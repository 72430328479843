import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '@/lib/supabase'

export function useRedirectAfterAuth(userId: string | undefined) {
  const navigate = useNavigate()

  useEffect(() => {
    const redirectToLastLocation = async () => {
      console.log('Starting redirect process, userId:', userId)
      if (!userId) {
        console.log('No userId provided, skipping redirect')
        return
      }

      try {
        // Get the return URL from the URL parameters
        const params = new URLSearchParams(window.location.search)
        const returnTo = params.get('returnTo')
        console.log('Return URL from params:', returnTo)

        if (returnTo) {
          console.log('Redirecting to return URL:', returnTo)
          // If we have a return URL, use it (make sure it's relative)
          const url = new URL(returnTo, window.location.origin)
          // Replace the current URL to avoid it being saved in history
          navigate(url.pathname + url.search + url.hash, { replace: true })
          return
        }

        console.log('Querying user_progress table for userId:', userId)
        // Get the latest user progress
        const { data, error } = await supabase
          .from('user_progress')
          .select('current_step')
          .eq('user_id', userId)
          .order('updated_at', { ascending: false })
          .limit(1)
          .single()

        console.log('Query result:', { data, error })

        if (error) {
          console.error('Database error:', error)
          if (error.code !== 'PGRST116') throw error
        }

        // If we have saved progress, redirect to the step
        if (data?.current_step) {
          console.log('Redirecting to saved step:', data.current_step)
          // Replace the current URL to avoid it being saved in history
          navigate(`/step/${data.current_step}`, { replace: true })
        } else {
          console.log('No saved progress found, redirecting to step 1')
          // If no saved progress, redirect to step 1
          navigate('/step/1', { replace: true })
        }
      } catch (error) {
        console.error('Error in redirect:', error)
        console.log('Redirecting to step 1 due to error')
        // On error, redirect to step 1
        navigate('/step/1', { replace: true })
      }
    }

    redirectToLastLocation()
  }, [userId, navigate])
}
