import { supabase } from '@/lib/supabase';
import { FormData } from '@/types/form';
import type { Database } from '@/types/supabase';

interface SavePlanParams {
  userId: string;
  plan: string;
  formData: FormData;
}

type UserPlan = Omit<Database['public']['Tables']['user_plans']['Row'], 'form_data'> & {
  form_data: FormData;
};

interface UserPlanStatus {
  user_id: string;
  has_generated_first_plan: boolean;
  first_plan_date: string | null;
}

export async function getUserPlans(userId: string): Promise<UserPlan[] | null> {
  if (!userId) return null;
  
  try {
    const { data, error } = await supabase
      .from('user_plans')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false })
      .returns<Database['public']['Tables']['user_plans']['Row'][]>()

    if (error) throw error;
    if (!data) return null;
    
    return data.map(plan => ({
      ...plan,
      form_data: plan.form_data as FormData,
      plan_text: plan.plan_text
    })) as UserPlan[];
  } catch (error) {
    console.error('Error fetching last plan:', error);
    return null;
  }
}

export async function saveUserPlan({ userId, plan, formData }: SavePlanParams): Promise<void> {
  if (!userId) throw new Error('User ID is required');
  if (!plan) throw new Error('Plan text is required');
  if (!formData) throw new Error('Form data is required');

  try {
    // Create a hash of the form data for tracking changes
    const formDataHash = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(JSON.stringify(formData))
    ).then(hash => {
      return Array.from(new Uint8Array(hash))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
    });

    const { error } = await supabase
      .from('user_plans')
      .insert([
        {
          user_id: userId,
          plan_text: plan,
          form_data: formData as unknown as Database['public']['Tables']['user_plans']['Insert']['form_data'],
          form_data_hash: formDataHash,
          created_at: new Date().toISOString(),
        } satisfies Database['public']['Tables']['user_plans']['Insert']
      ]);

    if (error) throw error;
  } catch (error) {
    console.error('Error saving plan:', error);
    throw error;
  }
}

export async function getLastUserPlan(plans: UserPlan[] | null): Promise<string | null> {
  if (!plans) return null;
  
  try {
    const lastPlan = plans?.[plans.length - 1];
    return lastPlan?.plan_text || null;
  } catch (error) {
    console.error('Error getting plan for form data:', error);
    return null;
  }
}

export async function getUserPlanStatus(userId: string): Promise<UserPlanStatus | null> {
  if (!userId) return null;

  try {
    const { data, error } = await supabase
      .from('user_plan_status')
      .select('*')
      .eq('user_id', userId)
      .single<Database['public']['Tables']['user_plan_status']['Row']>();

    if (error) {
      if (error.code === 'PGRST116') {
        // No record found, create one
        const newStatus = {
          user_id: userId,
          has_generated_first_plan: false,
          first_plan_date: null
        } satisfies Database['public']['Tables']['user_plan_status']['Insert'];

        const { error: insertError } = await supabase
          .from('user_plan_status')
          .insert([newStatus]);

        if (insertError) throw insertError;
        return newStatus;
      }
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error getting user plan status:', error);
    return null;
  }
}

export async function markFirstPlanGenerated(userId: string): Promise<void> {
  if (!userId) throw new Error('User ID is required');

  try {
    const { error } = await supabase
      .from('user_plan_status')
      .upsert([
        {
          user_id: userId,
          has_generated_first_plan: true,
          first_plan_date: new Date().toISOString()
        } satisfies Database['public']['Tables']['user_plan_status']['Insert']
      ]);

    if (error) throw error;
  } catch (error) {
    console.error('Error marking first plan as generated:', error);
    throw error;
  }
}
