import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/services/supabase';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { useAuth } from '@/hooks/useAuth';

export function ProviderLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user, signInWithPassword } = useAuth();

  // Handle navigation when user is authenticated
  useEffect(() => {
    const checkProvider = async () => {
      if (!user) return;

      try {
        if (!user?.id) return;

        const { data: provider, error } = await supabase
          .from('providers')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) throw error;

        if (provider && provider.status === 'active') {
          navigate('/provider/messages', { replace: true });
        } else if (provider) {
          toast({
            title: 'Account Pending',
            description: 'Your account is pending approval. You will be notified once approved.',
          });
        }
      } catch (error) {
        console.error('Provider check error:', error);
      }
    };

    checkProvider();
  }, [user, navigate, toast]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      console.log('Attempting login for:', email);
      const { data, error: signInError } = await signInWithPassword(email, password);

      console.log('Sign in result:', { data, error: signInError });

      if (signInError) {
        const errorMessage = signInError instanceof Error ? signInError.message : 'Unknown error';
        if (errorMessage.includes('Email not confirmed')) {
          throw new Error('Please confirm your email address before logging in. Check your inbox for the confirmation link.');
        }
        throw signInError;
      }

      if (!data?.user) {
        throw new Error('No user returned from login');
      }

      // Check if user is a provider and create record if needed
      const { data: provider, error: providerError } = await supabase
        .from('providers')
        .select('*')
        .eq('id', data.user.id)
        .single();

      console.log('Provider check:', { provider, error: providerError });

      if (providerError && !providerError.message.includes('No rows found')) {
        throw new Error(`Provider check failed: ${providerError.message}`);
      }

      if (!provider) {
        // Create provider record
        const { data: newProvider, error: createError } = await supabase
          .from('providers')
          .insert({
            id: data.user.id,
            email: data.user.email,
            name: data.user.user_metadata?.name || data.user.email,
            status: 'active'  // Or 'pending' if you want admin approval
          })
          .select('*')
          .single();

        if (createError) {
          throw new Error(`Failed to create provider record: ${createError.message}`);
        }

        if (!newProvider) {
          throw new Error('Failed to create provider record');
        }

        toast({
          title: 'Provider Account Created',
          description: 'Your provider account has been created successfully.',
        });

        // Navigate to messages
        navigate('/provider/messages', { replace: true });
        return;
      }

      if (provider.status !== 'active') {
        throw new Error('Your account is pending approval. You will be notified once approved.');
      }

      toast({
        title: 'Success',
        description: 'Successfully logged in',
      });

      // Navigate to messages
      navigate('/provider/messages', { replace: true });
    } catch (error: any) {
      console.error('Login error:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to log in',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto flex flex-col items-center justify-center min-h-[calc(100vh-4rem)]">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <h2 className="text-2xl font-bold">Provider Login</h2>
          <p className="text-muted-foreground mt-2">
            Sign in to access your provider dashboard
          </p>
        </div>

        <form onSubmit={handleLogin} className="mt-8 space-y-6">
          <div className="space-y-4">
            <div>
              <Input
                id="email"
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <Input
                id="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>

          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? 'Signing in...' : 'Sign in'}
          </Button>
        </form>
      </div>
    </div>
  );
}
