import { FC, ReactNode } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { Button } from '@/components/ui/button';
import { Rocket } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import GoogleLogo from './icons/GoogleLogo';
import { Link } from 'react-router-dom';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const { user, signIn, signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut();
    navigate('/', { replace: true });
  };

  return (
    <div className="min-h-screen bg-[#f5f6f8]">
      {/* Navigation Bar */}
      <nav className="fixed top-0 w-full bg-white shadow-sm z-50">
        <div className="max-w-[1600px] mx-auto px-4 lg:px-6 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <Rocket className="w-6 h-6 sm:w-8 sm:h-8 text-[#6FC9E7]" />
              <Link to="/" className="text-2xl sm:text-3xl font-black tracking-tight">
                <span className="text-[#6FC9E7]">
                  Raze
                </span>
              </Link>
            </div>
            <div className="flex items-center gap-4">
              {!user ? (
                <Button
                  onClick={() => signIn()}
                  variant="outline"
                  className="flex items-center gap-2"
                >
                  <GoogleLogo className="w-5 h-5" />
                  Sign in with Google
                </Button>
              ) : (
                <Button
                  onClick={handleSignOut}
                  variant="outline"
                  className="text-sm sm:text-base px-4 sm:px-6 py-2 rounded-full border-blue-300 hover:bg-blue-50"
                >
                  <span className="hidden sm:inline">Sign Out</span>
                  <GoogleLogo className="w-5 h-5 sm:hidden" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </nav>
      <div className="pt-20">
        {children}
      </div>
    </div>
  );
};

export default Layout;
