import React, {FC, useEffect, useState} from 'react';
import {FormData} from '@/types/form';
import {Card, CardContent} from '@/components/ui/card';
import {generateRelocationPlan} from '@/services/openai';
import {saveUserPlan, getLastUserPlan, getUserPlanStatus, markFirstPlanGenerated, getUserPlans} from '@/services/user';
import {Skeleton} from '@/components/ui/skeleton';
import {Button} from '@/components/ui/button';
import {ArrowLeft} from 'lucide-react';
import {useAuth} from '@/hooks/useAuth';
import {motion} from 'framer-motion';
import {ProvidersList} from '@/components/ProvidersList';
import {PlanSection, processPlanIntoSections} from '@/utils/planParser';
import {areFormDataEqual, getFormDataDifferences} from '@/utils/formDataComparison';
import {useToast} from '@/components/ui/use-toast';
import SharePlanModal from '@/components/SharePlanModal';

interface RelocationPlanProps {
    formData: FormData;
    onBack: () => void;
}

const RelocationPlan: FC<RelocationPlanProps> = ({formData, onBack}) => {
    const [plan, setPlan] = useState<string>('');
    const [planSections, setPlanSections] = useState<PlanSection[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const {user} = useAuth();
    const [isFetching, setIsFetching] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const {toast} = useToast();

    useEffect(() => {
        let isMounted = true;

        async function fetchPlan() {
            if (!user?.id || isFetching) {
                return;
            }

            try {
                setIsFetching(true);
                setError(null);

                // Get existing plans
                const existingPlans = await getUserPlans(user.id);
                
                if (existingPlans && existingPlans.length > 0) {
                    const lastPlan = existingPlans[0]; // Most recent plan
                    
                    // Compare current form data with stored form data
                    if (areFormDataEqual(formData, lastPlan.form_data)) {
                        // Use existing plan if form data hasn't changed
                        if (isMounted) {
                            setPlan(lastPlan.plan_text);
                            setPlanSections(processPlanIntoSections(lastPlan.plan_text));
                            toast({
                                title: "Using existing plan",
                                description: "No changes detected in your preferences",
                            });
                        }
                    } else {
                        // Generate new plan if form data has changed
                        const differences = getFormDataDifferences(formData, lastPlan.form_data);
                        const generatedPlan = await generateRelocationPlan(formData);
                        console.log('Raw OpenAI Response:', generatedPlan); // Debug log
                        if (generatedPlan && isMounted) {
                            await saveUserPlan({
                                userId: user.id,
                                plan: generatedPlan,
                                formData,
                            });
                            setPlan(generatedPlan);
                            setPlanSections(processPlanIntoSections(generatedPlan));
                            toast({
                                title: "Generated new plan",
                                description: `Changes detected in: ${differences.join(', ')}`,
                            });
                        }
                    }
                } else {
                    // Generate first plan
                    const generatedPlan = await generateRelocationPlan(formData);
                    console.log('Raw OpenAI Response:', generatedPlan); // Debug log
                    if (generatedPlan && isMounted) {
                        await saveUserPlan({
                            userId: user.id,
                            plan: generatedPlan,
                            formData,
                        });
                        await markFirstPlanGenerated(user.id);
                        setPlan(generatedPlan);
                        setPlanSections(processPlanIntoSections(generatedPlan));
                        toast({
                            title: "First plan generated",
                            description: "Your relocation plan is ready!",
                        });
                    }
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Error in RelocationPlan:', error);
                    setError(error instanceof Error ? error.message : 'Failed to generate plan. Please try again.');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                    setIsFetching(false);
                }
            }
        }

        fetchPlan();

        return () => {
            isMounted = false;
        };
    }, [user?.id, formData]);

    if (error) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 py-12">
                <div className="max-w-4xl mx-auto px-4">
                    <Card className="border-0 shadow-lg">
                        <CardContent className="p-6">
                            <div className="text-center space-y-4">
                                <div className="text-red-600 mb-4">
                                    {error}
                                </div>
                                <Button onClick={onBack} variant="outline" className="space-x-2">
                                    <ArrowLeft className="w-4 h-4"/>
                                    <span>Back to Form</span>
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }

    if (isLoading || !plan) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 py-12">
                <div className="max-w-4xl mx-auto px-4">
                    <Card className="border-0 shadow-lg">
                        <CardContent className="p-6">
                            <div className="space-y-4">
                                <Skeleton className="h-8 w-3/4"/>
                                <Skeleton className="h-4 w-full"/>
                                <Skeleton className="h-4 w-full"/>
                                <Skeleton className="h-4 w-2/3"/>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 px-4 py-8 md:px-8">
            <div className="max-w-4xl mx-auto">
                {/* Back button */}
                <Button 
                    onClick={onBack} 
                    variant="outline" 
                    className="mb-8 hover:bg-gray-50 text-gray-600"
                >
                    <ArrowLeft className="w-4 h-4 mr-2"/>
                    Back to Form
                </Button>

                {/* Title and welcome section */}
                <div className="flex flex-col items-center text-center mb-16">
                    <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
                        Your Personalized Relocation Plan
                    </h1>
                    <div className="max-w-2xl mx-auto bg-white p-8 rounded-xl shadow-sm border border-gray-100">
                        <p className="text-lg text-gray-800">
                            Below you'll find your AI-generated relocation plan. We encourage you to share it with our vetted relocation experts who can provide professional assessment, local insights, and assistance services.
                        </p>
                    </div>
                </div>

                <div className="space-y-6">
                    {planSections.map((section, index) => (
                        <motion.div
                            key={section.title}
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.1}}
                        >
                            <Card className="mb-6">
                                <CardContent className="p-6">
                                    <div className="flex items-center gap-4 mb-4">
                                        <div
                                            className={`inline-flex items-center justify-center p-2 rounded-lg bg-gradient-to-r ${section.gradient} text-white`}>
                                            {React.createElement(section.icon)}
                                        </div>
                                        <h2 className="text-2xl font-bold">{section.title}</h2>
                                    </div>
                                    <p className="text-gray-600 mb-4">{section.description}</p>
                                    <ul className="list-disc pl-6 space-y-2">
                                        {section.content.map((item, i) => (
                                            <li key={i} className="text-gray-700">{item}</li>
                                        ))}
                                    </ul>

                                    {(section.title === "Visa Requirements" ||
                                        section.title === "Financial Planning" ||
                                        section.title === "Health Insurance" ||
                                        section.title === "Language Preparation" ||
                                        section.title === "Adaptation Strategies" ||
                                        section.title === "Housing" ||
                                        section.title === "Education" ||
                                        section.title === "Transportation"
                                    ) && (
                                        <div className="mt-6">
                                            <div className="flex flex-col gap-4 mb-6">
                                                <h3 className="text-lg font-semibold">Recommended Experts</h3>
                                                <Button 
                                                    onClick={() => setIsShareModalOpen(true)}
                                                    variant="default"
                                                    className="bg-[#6FC9E7] hover:bg-[#5bb9d7] text-white w-fit transition-colors"
                                                >
                                                    {`Chat with All ${
                                                        section.title === "Visa Requirements" ? "Visa" :
                                                        section.title === "Financial Planning" ? "Finance" :
                                                        section.title === "Health Insurance" ? "Insurance" :
                                                        section.title === "Language Preparation" ? "Language" :
                                                        section.title === "Adaptation Strategies" ? "Cultural" :
                                                        section.title === "Housing" ? "Housing" :
                                                        section.title === "Education" ? "Education" :
                                                        section.title === "Transportation" ? "Transportation" :
                                                        ""
                                                    } Experts`}
                                                </Button>
                                            </div>
                                            <ProvidersList
                                                countryCode={formData.destinationCountries?.[0]?.code ?? ''}
                                                serviceType={
                                                    section.title === "Visa Requirements" ? "visa" :
                                                    section.title === "Financial Planning" ? "financial" :
                                                    section.title === "Health Insurance" ? "insurance" :
                                                    section.title === "Language Preparation" ? "language" :
                                                    section.title === "Adaptation Strategies" ? "cultural" :
                                                    section.title === "Housing" ? "housing" :
                                                    section.title === "Education" ? "education" :
                                                    section.title === "Transportation" ? "transportation" :
                                                    undefined
                                                }
                                            />
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        </motion.div>
                    ))}
                </div>
            </div>

            {/* Share Plan Modal */}
            <SharePlanModal
                isOpen={isShareModalOpen}
                onClose={() => setIsShareModalOpen(false)}
                formData={formData}
                plan={plan}
            />
        </div>
    );
};

export default RelocationPlan;
