import { FC, useState, useRef, useEffect } from 'react';
import { Check, X } from 'lucide-react';
import { cn } from "@/lib/utils";
import { Label } from '@/components/ui/label';
import { Profession } from '@/types/form';
import { Input } from './ui/input';

interface ProfessionSelectProps {
  label: string;
  placeholder: string;
  selected?: Profession;
  otherText?: string;
  onChange: (profession: Profession | undefined) => void;
  onOtherChange?: (text: string) => void;
  className?: string;
}

const ProfessionSelect: FC<ProfessionSelectProps> = ({
  label,
  placeholder,
  selected,
  otherText = '',
  onChange,
  onOtherChange,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setSearch('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (!isOpen) setIsOpen(true);
  };

  const selectProfession = (profession: Profession) => {
    onChange(profession);
    setIsOpen(false);
    setSearch('');
  };

  const removeProfession = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange(undefined);
  };

  const professions: Profession[] = [
    { id: 'software-engineer', name: 'Software Engineer' },
    { id: 'doctor', name: 'Doctor' },
    { id: 'teacher', name: 'Teacher' },
    { id: 'lawyer', name: 'Lawyer' },
    { id: 'accountant', name: 'Accountant' },
    { id: 'nurse', name: 'Nurse' },
    { id: 'designer', name: 'Designer' },
    { id: 'marketing', name: 'Marketing Professional' },
    { id: 'sales', name: 'Sales Professional' },
    { id: 'hr', name: 'HR Professional' },
    { id: 'finance', name: 'Finance Professional' },
    { id: 'business', name: 'Business Professional' },
    { id: 'other', name: 'Other' }
  ];

  const filteredProfessions = search
    ? professions.filter(p => 
        p.name.toLowerCase().includes(search.toLowerCase())
      )
    : professions;

  return (
    <div className="space-y-2" ref={dropdownRef}>
      <Label className="block text-lg font-medium mb-4">{label}</Label>
      <div className="relative">
        <div
          className={cn(
            "w-full min-h-[3.5rem] p-3 rounded-xl cursor-text border hover:border-gray-400 transition-colors",
            "bg-gray-100 text-gray-900 border-gray-200",
            className
          )}
          onClick={() => setIsOpen(true)}
        >
          <div className="flex flex-wrap gap-2">
            {selected ? (
              <span
                className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm flex items-center gap-1"
              >
                {selected.name}
                <button
                  onClick={removeProfession}
                  className="ml-1 hover:bg-blue-600 rounded-full p-0.5"
                >
                  <X className="h-3 w-3" />
                </button>
              </span>
            ) : (
              <input
                type="text"
                placeholder={placeholder}
                value={search}
                onChange={handleInputChange}
                className="bg-transparent border-none outline-none flex-1 placeholder-gray-500"
              />
            )}
          </div>
        </div>

        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto">
            {filteredProfessions.map(profession => (
              <div
                key={profession.id}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center justify-between"
                onClick={() => selectProfession(profession)}
              >
                {profession.name}
                {selected?.id === profession.id && (
                  <Check className="h-4 w-4 text-blue-500" />
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {selected?.id === 'other' && (
        <Input
          type="text"
          placeholder="Please specify your occupation..."
          value={otherText}
          onChange={(e) => onOtherChange?.(e.target.value)}
          className="mt-2"
        />
      )}
    </div>
  );
};

export default ProfessionSelect;