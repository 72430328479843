import { Card, CardContent } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import type { Message, Conversation } from "@/services/chat";
import { chatService } from "@/services/chat";
import { useEffect, useRef, useState, useCallback } from "react";
import { useAuth } from "@/hooks/useAuth";
import { Send, Loader2 } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useParams } from "react-router-dom";
import type { Database } from "@/types/supabase";

interface Props {
  conversationId?: string;
  providerId?: string;
  floating?: boolean;
}

// Add these constants at the top of the file, outside the component
const POLLING_INTERVAL_MIN = 2000; // 2 seconds
const POLLING_INTERVAL_MAX = 30000; // 30 seconds
const ACTIVITY_THRESHOLD = 300000; // 5 minutes in milliseconds

export const Chat: React.FC<Props> = ({
  conversationId: propConversationId,
  providerId: propProviderId,
  floating = false,
}) => {
  const { providerId: urlProviderId, conversationId: urlConversationId } =
    useParams<{ providerId?: string; conversationId?: string }>();
  const activeConversationId = propConversationId || urlConversationId;
  const activeProviderId = propProviderId || urlProviderId;
  const { user } = useAuth();
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isTyping, setIsTyping] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [pollingInterval, setPollingInterval] = useState(POLLING_INTERVAL_MIN);
  const lastMessageTimestampRef = useRef<string | null>(null);

  useEffect(() => {
    const initConversation = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        let conv: Conversation | null = null;

        // If we have a specific conversation ID, load it directly
        if (activeConversationId) {
          conv = await chatService.getConversationById(activeConversationId);

          // Verify user has access to this conversation
          if (conv.user_id !== user.id && conv.provider_id !== user.id) {
            throw new Error(
              "You do not have permission to view this conversation"
            );
          }
        }
        // If we're creating a new conversation with a provider (e.g. /chat/provider/[id])
        else if (activeProviderId) {
          // Check if current user is a provider
          const { data: provider } = await supabase
            .from("providers")
            .select("*")
            .eq("id", user.id)
            .maybeSingle<Database["public"]["Tables"]["providers"]["Row"]>();

          conv = await chatService.getOrCreateConversation(
            user.id,
            activeProviderId,
            !!provider
          );
        } else {
          throw new Error("No conversation ID or provider ID provided");
        }

        if (!conv) {
          throw new Error("No conversation found");
        }

        setConversation(conv);

        // If we have messages from the conversation query, use those
        if (conv.messages) {
          setMessages(conv.messages);
        } else {
          // Otherwise load messages separately
          const { data: messages, error: messagesError } = await supabase
            .from("messages")
            .select("*")
            .eq("conversation_id", conv.id)
            .order("timestamp", { ascending: true })
            .returns<Database["public"]["Tables"]["messages"]["Row"][]>();

          if (messagesError) {
            console.error("Error loading messages:", messagesError);
            throw new Error("Failed to load messages");
          }

          setMessages(
            (messages || []).map((msg) => ({ ...msg, is_read: false }))
          );
        }

        // Mark messages as read
        await chatService.markMessagesAsRead(conv.id, user.id);

        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      } catch (error: any) {
        console.error("Error initializing conversation:", error);
        setError(error.message || "Failed to load conversation");
      } finally {
        setIsLoading(false);
      }
    };

    initConversation();
  }, [user, activeProviderId, activeConversationId]);

  // Add this new function to fetch messages
  const fetchMessages = useCallback(async () => {
    if (!conversation?.id || !user) return;

    try {
      const { data: newMessages, error: messagesError } = await supabase
        .from("messages")
        .select("*")
        .eq("conversation_id", conversation.id)
        .gt("timestamp", lastMessageTimestampRef.current || "1970-01-01")
        .order("timestamp", { ascending: true })
        .returns<Database["public"]["Tables"]["messages"]["Row"][]>();

      if (messagesError) {
        console.error("Error fetching messages:", messagesError);
        return;
      }

      if (newMessages && newMessages.length > 0) {
        // Update messages and last message timestamp
        setMessages((prev) => {
          const uniqueMessages = [...prev];
          newMessages.forEach((newMsg) => {
            if (!uniqueMessages.some((msg) => msg.id === newMsg.id)) {
              uniqueMessages.push({ ...newMsg, is_read: false });
            }
          });
          return uniqueMessages;
        });

        lastMessageTimestampRef.current =
          newMessages[newMessages.length - 1].timestamp;

        // Reset polling interval to minimum when new messages arrive
        setPollingInterval(POLLING_INTERVAL_MIN);

        // Mark messages as read
        await chatService.markMessagesAsRead(conversation.id, user.id);

        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      } else {
        // Increase polling interval if no new messages (exponential backoff)
        setPollingInterval((prev) =>
          Math.min(prev * 1.5, POLLING_INTERVAL_MAX)
        );
      }
    } catch (error) {
      console.error("Error polling messages:", error);
    }
  }, [conversation?.id, user]);

  // Replace the existing realtime subscription useEffect with polling
  useEffect(() => {
    if (!conversation) return;

    // Set initial last message timestamp
    if (messages.length > 0) {
      lastMessageTimestampRef.current = messages[messages.length - 1].timestamp;
    }

    // Start polling
    const pollInterval = setInterval(fetchMessages, pollingInterval);

    return () => {
      clearInterval(pollInterval);
    };
  }, [conversation, pollingInterval, fetchMessages]);

  const handleTyping = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !conversation || !newMessage.trim()) return;

    setIsSending(true);
    try {
      const timestamp = new Date().toISOString();

      // Send the message
      const { data: message, error: sendError } = await supabase
        .from("messages")
        .insert({
          conversation_id: conversation.id,
          sender_id: user.id,
          content: newMessage.trim(),
          timestamp,
        } satisfies Database["public"]["Tables"]["messages"]["Insert"])
        .select()
        .single<Database["public"]["Tables"]["messages"]["Row"]>();

      if (sendError) {
        console.error("Error sending message:", sendError);
        throw sendError;
      }

      // Update conversation timestamp
      const { error: updateError } = await supabase
        .from("conversations")
        .update({
          created_at: timestamp,
        } satisfies Database["public"]["Tables"]["conversations"]["Update"])
        .eq("id", conversation.id);

      if (updateError) {
        console.error("Error updating conversation:", updateError);
      }

      setMessages((prev) => [
        ...prev,
        { ...message!, is_read: false } as Message,
      ]);
      setNewMessage("");

      // Check if this is the first message in the conversation
      if (messages.length === 0 && conversation?.provider) {
        // Add slight delay to make it feel more natural
        setTimeout(async () => {
          const provider = conversation.provider;
          if (!provider) return;

          const autoMessage = await chatService.sendAutoReply(
            conversation.id,
            provider.id,
            provider.full_name
          );

          if (autoMessage) {
            setMessages((prev) => [...prev, autoMessage]);
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        }, 1500); // 1.5 second delay
      }

      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

      // Reset polling interval when sending a message
      setPollingInterval(POLLING_INTERVAL_MIN);
    } catch (error) {
      console.error("Error sending message:", error);
      setError("Failed to send message");
    } finally {
      setIsSending(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[calc(100vh-4rem)]">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-[calc(100vh-4rem)]">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div
      className={
        floating
          ? "flex flex-col h-full"
          : "flex flex-col h-[calc(100vh-4rem)] max-w-2xl mx-auto p-4"
      }
    >
      <Card
        className={cn(
          "flex-grow overflow-hidden",
          floating && "border-0 shadow-none"
        )}
      >
        <CardContent className="h-full flex flex-col p-4 gap-4">
          <div
            className={cn(
              "flex-1 space-y-4 overflow-y-auto",
              floating ? "h-[calc(100%-80px)]" : "h-[calc(60vh-80px)]"
            )}
          >
            {messages.map((message) => (
              <div key={message.id} className="flex justify-start">
                <div
                  className={`rounded-lg px-4 py-2 max-w-[70%] ${
                    message.sender_id === user?.id
                      ? "bg-blue-100 text-blue-900"
                      : "bg-gray-100 text-gray-900"
                  }`}
                >
                  {message.content}
                </div>
              </div>
            ))}
            {isTyping && (
              <div className="flex justify-start">
                <div className="rounded-lg px-4 py-2 bg-muted">
                  <Loader2 className="h-4 w-4 animate-spin" />
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <form
            onSubmit={sendMessage}
            className="sticky bottom-0 bg-white pt-2 border-t"
          >
            <div className="flex gap-2">
              <Input
                value={newMessage}
                onChange={handleTyping}
                placeholder="Type a message..."
                className="flex-grow"
                disabled={isSending}
              />
              <Button
                type="submit"
                size="icon"
                disabled={isSending || !newMessage.trim()}
              >
                {isSending ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <Send className="h-4 w-4" />
                )}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
