import { useState, useEffect } from 'react';
import { Chat } from './Chat';
import { X, MessageCircle } from 'lucide-react';
import { Button } from './ui/button';
import { cn } from '@/lib/utils';

interface FloatingChatProps {
  providerId?: string;
  conversationId?: string;
  providerName?: string;
  onClose?: () => void;
}

export const FloatingChat = ({ 
  providerId, 
  conversationId, 
  providerName,
  onClose 
}: FloatingChatProps) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    onClose?.();
  };

  if (!isVisible) return null;

  const handleMinimizedClick = () => {
    if (isMinimized) {
      setIsMinimized(false);
    }
  };

  return (
    <div className={cn(
      "fixed bottom-4 left-4 z-50 flex flex-col",
      "bg-white rounded-lg shadow-lg",
      "transition-all duration-200 ease-in-out",
      isMinimized ? "w-[60px] h-[60px]" : "w-[400px] h-[600px]"
    )}>
      {/* Header */}
      <div className="flex items-center justify-between p-4 border-b">
        <div className="flex items-center space-x-2">
          <MessageCircle className="h-5 w-5" />
          <span className={cn(
            "font-medium transition-opacity duration-200",
            isMinimized ? "opacity-0" : "opacity-100"
          )}>
            {providerName || 'Chat'}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="ghost"
            size="sm"
            className="h-8 w-8 p-0"
            onClick={() => setIsMinimized(!isMinimized)}
          >
            {isMinimized ? (
              <MessageCircle className="h-4 w-4" />
            ) : (
              <svg
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            )}
          </Button>
          <Button
            variant="ghost"
            size="sm"
            className="h-8 w-8 p-0"
            onClick={handleClose}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>

      {/* Chat Content */}
      <div className={cn(
        "flex-1 transition-all duration-200",
        isMinimized ? "opacity-0 pointer-events-none" : "opacity-100"
      )}>
        <Chat 
          conversationId={conversationId} 
          providerId={providerId}
          floating={true}
        />
      </div>
    </div>
  );
};
