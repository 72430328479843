import { useEffect, useState } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { supabase } from '@/lib/supabase';
import { supabaseAdmin } from '@/services/supabaseAdmin';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Chat } from '@/components/Chat';

interface Conversation {
  id: string;
  user_id: string;
  provider_id: string;
  updated_at: string;
  last_message?: {
    content: string;
    timestamp: string;
  };
  user_email?: string;
}

export const ProviderMessages = () => {
  const { user } = useAuth();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedConversation, setSelectedConversation] = useState<string | null>(null);

  useEffect(() => {
    const fetchConversations = async () => {
      if (!user) return;

      try {
        // First get all conversations for this provider
        const { data: conversationsData, error: conversationsError } = await supabase
          .from('conversations')
          .select('*')
          .eq('provider_id', user.id)
          .order('updated_at', { ascending: false });

        if (conversationsError) throw conversationsError;

        // For each conversation, get the last message and user email
        const enrichedConversations = await Promise.all(
          (conversationsData || []).map(async (conv) => {
            // Get last message
            const { data: messages } = await supabase
              .from('messages')
              .select('content, timestamp')
              .eq('conversation_id', conv.id)
              .order('timestamp', { ascending: false })
              .limit(1);

            // Get user details from auth using admin client
            const { data: userData, error: userError } = await supabaseAdmin.auth.admin.getUserById(conv.user_id);
            
            if (userError) {
              console.error('Error fetching user:', userError);
            }

            return {
              ...conv,
              last_message: messages?.[0] || null,
              user_email: userData?.user?.email || 'Unknown User'
            };
          })
        );

        setConversations(enrichedConversations);
      } catch (err: any) {
        console.error('Error fetching conversations:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchConversations();
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Messages</h1>
      <div className="grid grid-cols-3 gap-4">
        {/* Left sidebar with conversations */}
        <div className="col-span-1 space-y-4">
          {conversations.map((conversation) => (
            <Card 
              key={conversation.id} 
              className={`cursor-pointer ${selectedConversation === conversation.id ? 'border-primary' : ''}`}
              onClick={() => setSelectedConversation(conversation.id)}
            >
              <CardContent className="p-4">
                <div>
                  <h2 className="font-semibold">
                    {conversation.user_email}
                  </h2>
                  {conversation.last_message && (
                    <p className="text-sm text-gray-500 truncate">
                      {conversation.last_message.content}
                    </p>
                  )}
                </div>
              </CardContent>
            </Card>
          ))}
        </div>

        {/* Right side chat area */}
        <div className="col-span-2">
          {selectedConversation ? (
            <Chat conversationId={selectedConversation} />
          ) : (
            <div className="h-full flex items-center justify-center text-gray-500">
              Select a conversation to view messages
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
