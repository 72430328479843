import { FormData, Country, Profession, Child } from '@/types/form';

export function areFormDataEqual(current: FormData, stored: FormData): boolean {
  // Compare basic fields
  const basicFieldsEqual = 
    current.name === stored.name &&
    current.age === stored.age &&
    current.maritalStatus === stored.maritalStatus &&
    current.status === stored.status &&
    current.familySize === stored.familySize &&
    current.numberOfKids === stored.numberOfKids &&
    current.budget === stored.budget &&
    current.timeline === stored.timeline &&
    current.additionalInfo === stored.additionalInfo;

  if (!basicFieldsEqual) return false;

  // Compare arrays of objects
  const arrayObjectsEqual = <T extends { [key: string]: any }>(arr1: T[] = [], arr2: T[] = [], key: keyof T): boolean => {
    if (arr1.length !== arr2.length) return false;
    const sorted1 = [...arr1].sort((a, b) => String(a[key]).localeCompare(String(b[key])));
    const sorted2 = [...arr2].sort((a, b) => String(a[key]).localeCompare(String(b[key])));
    return sorted1.every((obj, index) => {
      const obj2 = sorted2[index];
      return Object.keys(obj).every(key => obj[key] === obj2[key]);
    });
  };

  // Compare arrays of strings
  const arrayStringsEqual = (arr1: string[] = [], arr2: string[] = []): boolean => {
    if (arr1.length !== arr2.length) return false;
    const sorted1 = [...arr1].sort();
    const sorted2 = [...arr2].sort();
    return sorted1.every((value, index) => value === sorted2[index]);
  };

  // Compare complex arrays
  const arraysEqual = 
    arrayObjectsEqual(current.destinationCountries, stored.destinationCountries, 'code') &&
    arrayObjectsEqual(current.passportCountries, stored.passportCountries, 'code') &&
    arrayObjectsEqual(current.professions, stored.professions, 'id') &&
    arrayObjectsEqual(current.partnerProfessions, stored.partnerProfessions, 'id') &&
    arrayObjectsEqual(current.kids, stored.kids, 'age') &&
    arrayStringsEqual(current.targetCountries, stored.targetCountries);

  return arraysEqual;
}

export function getFormDataDifferences(current: FormData, stored: FormData): string[] {
  const differences: string[] = [];

  // Check basic fields
  if (current.name !== stored.name) 
    differences.push('Name');
  if (current.age !== stored.age) 
    differences.push('Age');
  if (current.maritalStatus !== stored.maritalStatus) 
    differences.push('Marital Status');
  if (current.status !== stored.status) 
    differences.push('Status');
  if (current.familySize !== stored.familySize) 
    differences.push('Family Size');
  if (current.numberOfKids !== stored.numberOfKids) 
    differences.push('Number of Kids');
  if (current.budget !== stored.budget) 
    differences.push('Budget');
  if (current.timeline !== stored.timeline) 
    differences.push('Timeline');
  if (current.additionalInfo !== stored.additionalInfo) 
    differences.push('Additional Info');

  // Compare arrays of objects
  const arrayObjectsChanged = <T extends { [key: string]: any }>(
    arr1: T[] = [], 
    arr2: T[] = [], 
    fieldName: string,
    key: keyof T
  ): void => {
    if (arr1.length !== arr2.length) {
      differences.push(fieldName);
      return;
    }
    const sorted1 = [...arr1].sort((a, b) => String(a[key]).localeCompare(String(b[key])));
    const sorted2 = [...arr2].sort((a, b) => String(a[key]).localeCompare(String(b[key])));
    if (!sorted1.every((obj, index) => {
      const obj2 = sorted2[index];
      return Object.keys(obj).every(key => obj[key] === obj2[key]);
    })) {
      differences.push(fieldName);
    }
  };

  // Compare arrays of strings
  const arrayStringsChanged = (arr1: string[] = [], arr2: string[] = [], fieldName: string): void => {
    if (arr1.length !== arr2.length || !arr1.every(item => arr2.includes(item))) {
      differences.push(fieldName);
    }
  };

  // Check complex arrays
  arrayObjectsChanged(current.destinationCountries, stored.destinationCountries, 'Destination Countries', 'code');
  arrayObjectsChanged(current.passportCountries, stored.passportCountries, 'Passport Countries', 'code');
  arrayObjectsChanged(current.professions, stored.professions, 'Professions', 'id');
  arrayObjectsChanged(current.partnerProfessions, stored.partnerProfessions, 'Partner Professions', 'id');
  arrayObjectsChanged(current.kids, stored.kids, 'Kids', 'age');
  arrayStringsChanged(current.targetCountries, stored.targetCountries, 'Target Countries');

  return differences;
}
