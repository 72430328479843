import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Plan from './pages/Plan';
import Step from './pages/Step';
import Admin from './pages/Admin';
import AuthCallback from './pages/AuthCallback';
import Layout from './components/Layout';
import { Toaster } from '@/components/ui/toaster';
import DebugPlanParser from './components/DebugPlanParser';
import { ProviderLogin } from './pages/ProviderLogin';
import { ProviderSignUp } from './pages/ProviderSignUp';
import { ProviderMessages } from './pages/ProviderMessages';
import { Chat } from '@/components/Chat';
import { ProviderRoute } from '@/components/ProviderRoute';

const App: FC = () => {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/step/:step" element={<Step />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/debug" element={<DebugPlanParser />} />
          
          {/* Public provider routes */}
          <Route path="/provider/login" element={<ProviderLogin />} />
          <Route path="/provider/signup" element={<ProviderSignUp />} />
          
          {/* Protected provider routes */}
          <Route path="/provider" element={<ProviderRoute />}>
            <Route index element={<Navigate to="messages" replace />} />
            <Route path="messages" element={<ProviderMessages />} />
          </Route>

          {/* User chat route */}
          <Route path="/chat/:providerId" element={<Chat />} />
        </Routes>
      </Layout>
      <Toaster />
    </>
  );
};

export default App;