import React, { useEffect, useState } from 'react';
import { generateRelocationPlan } from '@/services/openai';
import { processPlanIntoSections, PlanSection } from '@/utils/planParser';
import { FormData } from '@/types/form';
import { Button } from './ui/button';

const testFormData: FormData = {
    familySize: 'family',
    destinationCountries: [
        { code: 'DE', name: 'Germany' }
    ],
    passportCountries: [
        { code: 'IL', name: 'Israel' }
    ],
    professions: [
        { id: 'software-engineer', name: 'Software Engineer' }
    ],
    partnerProfessions: [
        { id: 'teacher', name: 'Teacher' }
    ],
    children: 2,
    childrenAges: ['5', '8'],
    budget: '100k+',
    timeline: '6 months',
    additionalInfo: 'Looking for tech hubs and good schools'
};

export function DebugPlanParser() {
    const [plan, setPlan] = useState<string>('');
    const [sections, setSections] = useState<PlanSection[]>([]);
    const [rawSections, setRawSections] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [selectedSection, setSelectedSection] = useState<number | null>(null);

    const analyzePlan = (text: string) => {
        // Split into sections for analysis
        const splitSections = text.split('\n\n').filter(Boolean);
        setRawSections(splitSections);

        // Get all potential section titles
        const potentialTitles = splitSections.map(section => {
            const lines = section.split('\n');
            return lines[0].replace(':', '').trim();
        });

        console.log('Potential section titles:', potentialTitles);
        
        // Process the plan
        const parsedSections = processPlanIntoSections(text);
        setSections(parsedSections);

        // Log detailed analysis
        console.log('Number of raw sections:', splitSections.length);
        console.log('Number of parsed sections:', parsedSections.length);
        
        return { splitSections, parsedSections };
    };

    const generatePlan = async () => {
        setLoading(true);
        setError('');
        try {
            const generatedPlan = await generateRelocationPlan(testFormData);
            setPlan(generatedPlan);
            analyzePlan(generatedPlan);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to generate plan');
        } finally {
            setLoading(false);
        }
    };

    const analyzeSection = (index: number) => {
        if (index >= 0 && index < rawSections.length) {
            const section = rawSections[index];
            const lines = section.split('\n');
            const rawTitle = lines[0].replace(':', '').trim();
            
            // Test each regex pattern
            const patterns = {
                visa: /visa|passport|immigration|document|requirement/i,
                financial: /financ|cost|budget|expense|tax|pension|money|planning/i,
                health: /health|insurance|medical|healthcare|coverage/i,
                language: /language|speak|commun|preparation/i,
                adaptation: /adapt|cultur|integrat|network|social|custom|strateg/i,
                housing: /hous|rent|apartment|accommodation|living/i,
                education: /educat|school|study|learn|academic/i,
                transport: /transport|travel|commut|car|transit|mobility/i,
            };

            const matches = Object.entries(patterns).reduce((acc, [key, pattern]) => {
                acc[key] = {
                    matches: pattern.test(rawTitle),
                    matchedText: rawTitle.match(pattern)?.[0] || null
                };
                return acc;
            }, {} as Record<string, { matches: boolean; matchedText: string | null }>);

            console.log('Section Analysis:', {
                rawTitle,
                content: lines.slice(1),
                regexMatches: matches
            });
        }
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <h1 className="text-2xl font-bold mb-4">Plan Parser Debug</h1>
            
            <Button 
                onClick={generatePlan}
                disabled={loading}
                className="mb-6"
            >
                {loading ? 'Generating...' : 'Generate Test Plan'}
            </Button>

            {error && (
                <div className="text-red-500 mb-4">
                    Error: {error}
                </div>
            )}

            {plan && (
                <>
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-2">Raw Plan:</h2>
                        <pre className="bg-gray-100 p-4 rounded overflow-auto">
                            {plan}
                        </pre>
                    </div>

                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-2">Raw Sections Analysis:</h2>
                        {rawSections.map((section, index) => (
                            <div key={index} className="mb-4 border p-4 rounded">
                                <div className="flex justify-between items-center mb-2">
                                    <h3 className="font-semibold">
                                        Section {index + 1}: {section.split('\n')[0]}
                                    </h3>
                                    <Button
                                        onClick={() => {
                                            setSelectedSection(index);
                                            analyzeSection(index);
                                        }}
                                        variant="outline"
                                        size="sm"
                                    >
                                        Analyze
                                    </Button>
                                </div>
                                {selectedSection === index && (
                                    <pre className="bg-gray-50 p-2 rounded text-sm">
                                        {section}
                                    </pre>
                                )}
                            </div>
                        ))}
                    </div>

                    <div>
                        <h2 className="text-xl font-semibold mb-2">Parsed Sections:</h2>
                        {sections.map((section, index) => (
                            <div key={index} className="mb-6 border p-4 rounded">
                                <h3 className="font-semibold flex items-center gap-2">
                                    <section.icon className="w-5 h-5" />
                                    {section.title}
                                </h3>
                                <div className={`h-1 ${section.gradient} rounded my-2`} />
                                <p className="text-gray-600 mb-2">{section.description}</p>
                                <ul className="list-disc pl-5">
                                    {section.content.map((item, i) => (
                                        <li key={i}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default DebugPlanParser;
