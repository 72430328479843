import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import { supabase } from '@/services/supabase';
import { useToast } from '@/components/ui/use-toast';

export const ProviderRoute = () => {
  const { user, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const checkProvider = async () => {
      if (!user) {
        setIsChecking(false);
        return;
      }

      try {
        // Check if user has provider role
        if (user.user_metadata?.role !== 'provider') {
          console.error('User is not a provider');
          toast({
            title: 'Access Denied',
            description: 'You must be a provider to access this page',
            variant: 'destructive',
          });
          navigate('/');
          return;
        }

        // Verify provider record exists
        const { data: provider, error } = await supabase
          .from('providers')
          .select('*')
          .eq('id', user.id)
          .single();

        console.log('ProviderRoute - Provider check:', { provider, error });

        if (error || !provider) {
          console.error('Provider record not found:', error);
          toast({
            title: 'Access Denied',
            description: 'Provider account not found',
            variant: 'destructive',
          });
          navigate('/');
          return;
        }

        console.log('ProviderRoute - Provider verified');
        setIsVerified(true);
      } catch (error) {
        console.error('Error verifying provider:', error);
        toast({
          title: 'Error',
          description: 'Failed to verify provider access',
          variant: 'destructive',
        });
        navigate('/');
      } finally {
        setIsChecking(false);
      }
    };

    checkProvider();
  }, [user, navigate, toast]);

  // Show loading state while checking auth and provider status
  if (authLoading || isChecking) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  // Only render outlet if we have a user and is verified
  if (!user || !isVerified) {
    return null;
  }

  return <Outlet />;
};
