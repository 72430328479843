import { FC, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RelocationPlan from '@/components/RelocationPlan';
import { FormData } from '@/types/form';

interface LocationState {
  formData: FormData;
}

const Plan: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  useEffect(() => {
    // If there's no form data in state, redirect to the form
    if (!state?.formData || !state.formData.destinationCountries?.length) {
      navigate('/');
    }
  }, [state, navigate]);

  if (!state?.formData || !state.formData.destinationCountries?.length) {
    return null;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <RelocationPlan 
        formData={state.formData} 
        onBack={() => navigate('/')} 
      />
    </div>
  );
};

export default Plan;
