import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/services/supabase';
import { chatService } from '@/services/chat';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';

export function ProviderSignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Sign up the user first
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            full_name: name,
            role: 'provider'
          }
        }
      });

      if (authError) {
        if (authError.message.includes('security purposes') || authError.status === 429) {
          toast({
            title: 'Please Wait',
            description: 'For security reasons, please wait a moment before trying again.',
            variant: 'default',
          });
          return;
        }
        throw authError;
      }
      
      if (!authData.user) throw new Error('No user data returned');

      console.log('Auth user created:', {
        id: authData.user.id,
        email: authData.user.email,
        role: authData.user.user_metadata?.role
      });

      // Create the provider record right after signup
      if (!authData.user.email) {
        throw new Error('Email is required');
      }

      const { data: providerData, error: providerError } = await chatService.createProvider({
        id: authData.user.id,
        full_name: authData.user.user_metadata?.full_name || authData.user.email.split('@')[0],
        title: 'New Provider',
        bio: '',
        expertise: [],
        languages: [],
        email: authData.user.email,
        status: 'pending'
      });

      if (providerError) {
        console.error('Failed to create provider record:', providerError);
        throw new Error(`Failed to create provider record: ${providerError}`);
      }

      console.log('Provider record created:', providerData);

      // Show success message with email confirmation instructions
      toast({
        title: 'Check Your Email',
        description: 'Please check your email for a confirmation link. Once confirmed, you can log in to your account.',
        duration: 6000, // Show for longer
      });

      // Navigate to a confirmation page or login
      navigate('/provider/login', { 
        replace: true,
        state: { message: 'Please check your email and confirm your account before logging in.' }
      });

    } catch (error: any) {
      console.error('Signup error:', error);
      
      let errorMessage = 'Failed to create account';
      if (error.message?.includes('security purposes')) {
        errorMessage = 'Please wait a moment before trying again';
      } else if (error.message?.includes('already registered')) {
        errorMessage = 'This email is already registered';
      } else if (error.message?.includes('Failed to create provider record')) {
        errorMessage = 'Failed to create provider account. Please try again or contact support.';
      }
      
      toast({
        title: 'Error',
        description: errorMessage,
        variant: 'destructive',
      });

      // Clean up if needed
      try {
        await supabase.auth.signOut();
      } catch (cleanupError) {
        console.error('Cleanup error:', cleanupError);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container flex items-center justify-center min-h-screen py-8">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">Provider Sign Up</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSignUp} className="space-y-4">
            <div className="space-y-2">
              <Input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div className="space-y-2">
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div className="space-y-2">
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoading}
                minLength={6}
              />
            </div>
            <Button
              type="submit"
              className="w-full"
              disabled={isLoading}
            >
              {isLoading ? 'Creating Account...' : 'Sign Up'}
            </Button>
            <div className="text-center text-sm">
              <span className="text-muted-foreground">Already have an account? </span>
              <Button
                variant="link"
                className="p-0"
                onClick={() => navigate('/provider/login')}
                disabled={isLoading}
              >
                Log in
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
