import { FC, useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { createBrowserClient } from '@/lib/supabase-browser';
import { Button } from '@/components/ui/button';
import { MessageCircle } from 'lucide-react';
import { FloatingChat } from './FloatingChat';
import type { Database } from '@/types/supabase';

type Provider = Database['public']['Tables']['providers']['Row'];

interface ProvidersListProps {
  countryCode: string;
  serviceType?: string;
}

// Map country codes to full names
const countryCodeToName: Record<string, string> = {
  'US': 'United States',
  'CA': 'Canada',
  'GB': 'United Kingdom',
  'DE': 'Germany',
  'AT': 'Austria',
  'CH': 'Switzerland',
  'AU': 'Australia',
  'NZ': 'New Zealand',
  'IL': 'Israel',
  'IE': 'Ireland',
  'IT': 'Italy'
};

// Map service types to expertise
const serviceToExpertise: Record<string, string> = {
  'visa': 'visa',
  'housing': 'housing',
  'financial': 'banking',
  'insurance': 'healthcare',
  'language': 'language preparation',
  'cultural': 'cultural integration',
  'education': 'education',
  'transportation': 'lifestyle',
  'employment': 'job search',
  'legal': 'visa',
  'general': 'relocation'
};

const serviceColorMap: Record<string, string> = {
  visa: 'bg-blue-100 text-blue-800',
  housing: 'bg-green-100 text-green-800',
  employment: 'bg-purple-100 text-purple-800',
  education: 'bg-yellow-100 text-yellow-800',
  insurance: 'bg-red-100 text-red-800',
  legal: 'bg-indigo-100 text-indigo-800',
  financial: 'bg-emerald-100 text-emerald-800',
  transportation: 'bg-orange-100 text-orange-800',
  cultural: 'bg-pink-100 text-pink-800',
  language: 'bg-cyan-100 text-cyan-800',
  general: 'bg-gray-100 text-gray-800',
  'real estate': 'bg-amber-100 text-amber-800',
  healthcare: 'bg-rose-100 text-rose-800',
  'job search': 'bg-violet-100 text-violet-800',
  'cultural integration': 'bg-fuchsia-100 text-fuchsia-800',
  'language preparation': 'bg-sky-100 text-sky-800',
  banking: 'bg-lime-100 text-lime-800',
  lifestyle: 'bg-teal-100 text-teal-800',
  aliyah: 'bg-indigo-100 text-indigo-800',
  relocation: 'bg-blue-100 text-blue-800'
};

export const ProvidersList: FC<ProvidersListProps> = ({ countryCode, serviceType }) => {
  const [providers, setProviders] = useState<Provider[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeChat, setActiveChat] = useState<{ providerId: string; name: string } | null>(null);

  useEffect(() => {
    const fetchProviders = async () => {
      const supabase = createBrowserClient();
      try {
        const countryName = countryCodeToName[countryCode.toUpperCase()];
        if (!countryName) {
          console.warn(`No country name mapping found for code: ${countryCode}`);
          setProviders([]);
          return;
        }

        let query = supabase
          .from('providers')
          .select('*')
          .contains('countries', [countryName]);

        if (serviceType) {
          const expertise = serviceToExpertise[serviceType];
          if (expertise) {
            query = query.contains('expertise', [expertise]);
          }
        }

        const { data, error: queryError } = await query
          .order('rating', { ascending: false })
          .returns<Database['public']['Tables']['providers']['Row'][]>();

        if (queryError) throw queryError;
        setProviders(data || []);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch providers');
      } finally {
        setLoading(false);
      }
    };

    fetchProviders();
  }, [countryCode, serviceType]);

  const handleChatClick = (provider: Database['public']['Tables']['providers']['Row']) => {
    if (!provider.id || !provider.full_name) return;
    setActiveChat({ providerId: provider.id, name: provider.full_name });
  };

  if (loading) {
    return <div>Loading providers...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (providers.length === 0) {
    return <div>No providers found for this location and service.</div>;
  }

  return (
    <div className="space-y-4">
      {providers.map((provider) => (
        <Card key={provider.id}>
          <CardContent className="p-4">
            <div className="flex items-start space-x-4">
              <Avatar className="h-12 w-12">
                <AvatarImage src={provider.profile_image_url || ''} alt={provider.full_name} />
                <AvatarFallback>
                  {provider.full_name?.split(' ').map(n => n[0]).join('') || 'N/A'}
                </AvatarFallback>
              </Avatar>
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <h3 className="font-semibold">{provider.full_name}</h3>
                  <Button 
                    onClick={() => handleChatClick(provider)}
                    className="bg-[#FEE5B5] hover:bg-[#ecd19d] text-gray-800 
                             transform hover:-translate-y-0.5 transition-all shadow-md hover:shadow-lg
                             flex items-center space-x-2"
                  >
                    <MessageCircle className="h-4 w-4" />
                    <span>Chat with Me</span>
                  </Button>
                </div>
                {provider.title && <p className="text-sm text-gray-500">{provider.title}</p>}
                {provider.bio && <p className="text-sm mt-1">{provider.bio}</p>}
                <div className="mt-2 flex flex-wrap gap-2">
                  {provider.expertise?.map((exp) => (
                    <Badge key={exp} className={serviceColorMap[exp] || serviceColorMap.general}>
                      {exp}
                    </Badge>
                  ))}
                </div>
                <div className="mt-2 text-sm text-gray-500">
                  <span>Languages: {provider.languages?.join(', ') || 'N/A'}</span>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
      {activeChat && (
        <FloatingChat
          providerId={activeChat.providerId}
          providerName={activeChat.name}
          onClose={() => setActiveChat(null)}
        />
      )}
    </div>
  );
};
