import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { supabase } from '@/lib/supabase'
import { Country } from '@/types/form'
import { Mentor, MentorFormData } from '@/types/admin'

export function MentorForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [formData, setFormData] = useState<MentorFormData>({
    name: '',
    phone: '',
    email: '',
    country_code: '',
    relocated_at: ''
  })
  const [countries, setCountries] = useState<Country[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch countries
        const { data: countriesData, error: countriesError } = await supabase
          .from('countries')
          .select('code, name')
          .order('name')

        if (countriesError) throw countriesError
        setCountries(countriesData as Country[])

        // If editing, fetch mentor data
        if (id) {
          const { data: mentorData, error: mentorError } = await supabase
            .from('mentors')
            .select('*')
            .eq('id', id)
            .single()

          if (mentorError) throw mentorError
          setFormData(mentorData)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [id])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)

    try {
      const { error } = id
        ? await supabase
            .from('mentors')
            .update(formData)
            .eq('id', id)
        : await supabase
            .from('mentors')
            .insert(formData)

      if (error) throw error

      navigate('/admin/mentors')
    } catch (error) {
      console.error('Error saving mentor:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-800 mb-6">
        {id ? 'Edit Mentor' : 'New Mentor'}
      </h2>

      <form onSubmit={handleSubmit} className="max-w-2xl bg-white rounded-xl shadow-sm p-6 space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-3 border border-gray-200 rounded-lg"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Phone
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-3 border border-gray-200 rounded-lg"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-3 border border-gray-200 rounded-lg"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Country
          </label>
          <select
            name="country_code"
            value={formData.country_code}
            onChange={handleChange}
            className="w-full p-3 border border-gray-200 rounded-lg"
            required
          >
            <option value="">Select a country</option>
            {countries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Relocation Date
          </label>
          <input
            type="date"
            name="relocated_at"
            value={formData.relocated_at}
            onChange={handleChange}
            className="w-full p-3 border border-gray-200 rounded-lg"
            required
          />
        </div>

        <div className="mt-8 flex justify-end gap-4">
          <button
            type="button"
            onClick={() => navigate('/admin/mentors')}
            className="px-6 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
          >
            {loading ? 'Saving...' : id ? 'Update Mentor' : 'Create Mentor'}
          </button>
        </div>
      </form>
    </div>
  )
}