import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { 
  ArrowRight, Building2, Briefcase, GraduationCap, 
  Heart, Users, Globe2, Wallet, Scale
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import RelocationForm from '../components/RelocationForm';
import { useAuth } from '@/hooks/useAuth';
import { useUserProgress } from '@/hooks/useUserProgress';
import '../styles/custom.css';

function Home() {
  const [showForm, setShowForm] = useState(false);
  const { user, signIn } = useAuth();
  const { currentStep } = useUserProgress();

  useEffect(() => {
    if (user && currentStep > 0) {
      setShowForm(true);
    }
  }, [user, currentStep]);

  const handleGetStarted = async () => {
    if (!user) {
      await signIn({ type: 'showForm' });
      return;
    }
    setShowForm(true);
  };

  return (
    <div className="flex min-h-screen flex-col">
      {/* Top Banner */}
      <div className="bg-[#6FC9E7] px-4 py-3 text-center text-white">
        <span className="inline-flex items-center gap-3 text-lg">
          🎁 Get expert relocation assistance when you join Raze |{" "}
          <button onClick={handleGetStarted} className="underline text-lg font-medium hover:text-white/90">
            Learn more
          </button>
        </span>
      </div>

      <main className="flex-1">
        {showForm ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8"
          >
            <RelocationForm />
          </motion.div>
        ) : (
          <>
            {/* Hero Section */}
            <div className="relative overflow-hidden bg-[#CDEAF7] px-4 pt-16 pb-8 md:px-8">
              <div className="mx-auto max-w-7xl">
                <div className="grid gap-12 md:grid-cols-2">
                  <div className="max-w-2xl">
                    <div className="mb-8 inline-flex items-center rounded-full border bg-white px-4 py-2">
                      <div className="flex items-center gap-2">
                        <span className="text-sm text-gray-600">⭐ Trusted by 500+ relocation experts</span>
                      </div>
                    </div>
                    <motion.h1 
                      className="mb-6 text-5xl font-bold tracking-tight text-gray-900"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Navigate Your Move
                    </motion.h1>
                    <motion.p 
                      className="mb-8 text-xl text-gray-600"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.2 }}
                    >
                      Start with AI-powered recommendations, then plan with real experts to make your relocation seamless.
                    </motion.p>

                    <motion.div 
                      className="mt-8 flex flex-col gap-4 sm:flex-row"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.4 }}
                    >
                      <Button 
                        size="lg" 
                        className="bg-[#6FC9E7] hover:bg-[#6FC9E7]/90 text-lg py-6 w-[280px] rounded-full"
                        onClick={handleGetStarted}
                      >
                        Start Your Journey
                      </Button>
                    </motion.div>
                  </div>

                  <div className="relative hidden md:block">
                    <div className="absolute inset-0 bg-gradient-to-b from-[#CDEAF7] to-transparent"></div>
                    <div className="grid grid-cols-3 grid-rows-3 gap-3 max-w-[600px] ml-auto">
                      {/* Top row */}
                      <motion.div 
                        className="relative col-span-1 row-span-1 h-32 overflow-hidden rounded-2xl bg-[#FEE5B5]"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                      >
                        <img
                          src="https://images.pexels.com/photos/346793/pexels-photo-346793.jpeg?auto=compress&cs=tinysrgb&w=300&h=300&dpr=2"
                          alt="Passport with toy plane"
                          className="h-full w-full object-cover"
                        />
                      </motion.div>
                      <motion.div 
                        className="relative col-span-1 row-span-1 h-32 overflow-hidden rounded-2xl bg-[#FECCA8]"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                      >
                        <img
                          src="https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?q=80&w=300&h=300&auto=format&fit=crop"
                          alt="House front"
                          className="h-full w-full object-cover"
                        />
                      </motion.div>
                      <motion.div 
                        className="relative col-span-1 row-span-1 h-32 overflow-hidden rounded-2xl bg-[#6FC9E7]"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                      >
                        <img
                          src="https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?q=80&w=300&h=300&auto=format&fit=crop"
                          alt="Finance chart"
                          className="h-full w-full object-cover"
                        />
                      </motion.div>

                      {/* Middle row */}
                      <motion.div 
                        className="relative col-span-1 row-span-1 h-32 overflow-hidden rounded-2xl bg-[#99D4E9]"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                      >
                        <img
                          src="https://images.unsplash.com/photo-1436491865332-7a61a109cc05?q=80&w=300&h=300&auto=format&fit=crop"
                          alt="Airplane wing"
                          className="h-full w-full object-cover"
                        />
                      </motion.div>
                      <motion.div 
                        className="relative col-span-2 row-span-1 h-32 overflow-hidden rounded-2xl bg-[#B3DEEF]"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                      >
                        <img
                          src="https://images.unsplash.com/photo-1511895426328-dc8714191300?q=80&w=600&h=300&auto=format&fit=crop"
                          alt="Family unpacking"
                          className="h-full w-full object-cover"
                        />
                      </motion.div>

                      {/* Bottom row */}
                      <motion.div 
                        className="relative col-span-2 row-span-1 h-32 overflow-hidden rounded-2xl bg-[#CDEAF7]"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                      >
                        <img
                          src="https://images.unsplash.com/photo-1434082033009-b81d41d32e1c?q=80&w=600&h=300&auto=format&fit=crop"
                          alt="New city skyline"
                          className="h-full w-full object-cover"
                        />
                      </motion.div>
                      <motion.div 
                        className="relative col-span-1 row-span-1 h-32 overflow-hidden rounded-2xl bg-[#FEE5B5]"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.7 }}
                      >
                        <img
                          src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=300&h=300&auto=format&fit=crop"
                          alt="Business meeting"
                          className="h-full w-full object-cover"
                        />
                      </motion.div>
                    </div>

                    {/* Floating cards */}
                    <motion.div 
                      className="absolute -left-4 top-1/4 w-48 rounded-xl bg-white p-4 shadow-lg"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 0.8 }}
                    >
                      <div className="flex items-center gap-3">
                        <div className="rounded-full bg-[#FEE5B5] p-2">
                          <svg className="h-4 w-4 text-[#6FC9E7]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <div className="text-sm">
                          <p className="font-medium">Visa Approved</p>
                          <p className="text-gray-500">2 days ago</p>
                        </div>
                      </div>
                    </motion.div>

                    <motion.div 
                      className="absolute -right-4 bottom-1/4 w-48 rounded-xl bg-white p-4 shadow-lg"
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 0.9 }}
                    >
                      <div className="flex items-center gap-3">
                        <div className="rounded-full bg-[#FECCA8] p-2">
                          <svg className="h-4 w-4 text-[#6FC9E7]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                            />
                          </svg>
                        </div>
                        <div className="text-sm">
                          <p className="font-medium">Housing Found</p>
                          <p className="text-gray-500">Just now</p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>

            {/* Features Section */}
            <div className="mt-32 overflow-hidden">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                  <motion.h2 
                    className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    What would you like help with?
                  </motion.h2>
                </div>

                <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:gap-y-12 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                  {[
                    { title: 'Housing', description: 'Find your perfect home abroad', icon: '🏠' },
                    { title: 'Employment', description: 'Secure your dream job', icon: '💼' },
                    { title: 'Education', description: 'Discover learning opportunities', icon: '📚' },
                    { title: 'Healthcare', description: 'Access quality healthcare', icon: '🏥' },
                    { title: 'Community', description: 'Connect with fellow expats', icon: '👥' },
                    { title: 'Travel', description: 'Plan your journeys easily', icon: '✈️' },
                    { title: 'Finance', description: 'Manage money across borders', icon: '💳' },
                    { title: 'Legal', description: 'Navigate regulations & visas', icon: '⚖️' },
                  ].map((feature, index) => (
                    <motion.div
                      key={feature.title}
                      className="relative flex flex-col gap-3 rounded-2xl bg-white p-6 shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1 hover:bg-[#F8FCFE]"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-[#E5F6FF] text-2xl">
                        {feature.icon}
                      </div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        {feature.title}
                      </h3>
                      <p className="text-gray-600">
                        {feature.description}
                      </p>
                      <div className="mt-3">
                        <button 
                          onClick={handleGetStarted}
                          className="inline-flex items-center text-[#6FC9E7] hover:text-[#6FC9E7]/80"
                        >
                          Learn more
                          <span className="ml-1">→</span>
                        </button>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>

            {/* Stats Section */}
            <div className="mt-32 bg-[#E5F6FF]">
              <div className="mx-auto max-w-7xl px-6 py-20">
                <div className="grid gap-16 md:grid-cols-3 text-center">
                  <div className="flex flex-col items-center">
                    <h3 className="text-8xl font-extralight text-[#6FC9E7]">500+</h3>
                    <p className="mt-4 text-base font-normal text-gray-600">Relocation Experts</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <h3 className="text-8xl font-extralight text-[#6FC9E7]">50+</h3>
                    <p className="mt-4 text-base font-normal text-gray-600">Countries Covered</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <h3 className="text-8xl font-extralight text-[#6FC9E7]">Free</h3>
                    <p className="mt-4 text-base font-normal text-gray-600">Basic Plan</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </main>
    </div>
  );
}

export default Home;