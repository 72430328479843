import { useState, useEffect, useRef } from 'react'
import { createBrowserClient } from '@/lib/supabase-browser'
import type { User } from '@supabase/supabase-js'

export function useAuth() {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const supabase = createBrowserClient()
  const subscriptionRef = useRef<{ unsubscribe: () => void } | null>(null)

  useEffect(() => {
    // Get initial session
    const initAuth = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession()
        console.log('Initial session check:', { session, error })
        
        if (error) {
          console.error('Session error:', error)
          return
        }
        
        if (session?.user) {
          console.log('Setting initial user:', session.user)
          setUser(session.user)
        }
      } catch (error) {
        console.error('Auth initialization error:', error)
      } finally {
        setLoading(false)
      }
    }

    // Clean up previous subscription if it exists
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe()
    }

    initAuth()

    // Set up new subscription
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state change:', { event, session })
      
      if (event === 'SIGNED_IN') {
        console.log('User signed in:', session?.user)
        setUser(session?.user ?? null)
      } else if (event === 'SIGNED_OUT') {
        console.log('User signed out')
        setUser(null)
      } else if (event === 'TOKEN_REFRESHED') {
        console.log('Token refreshed:', session?.user)
        setUser(session?.user ?? null)
      }
    })

    // Store subscription reference
    subscriptionRef.current = subscription

    // Cleanup function
    return () => {
      console.log('Cleaning up auth subscription')
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe()
      }
    }
  }, []) // Empty dependency array

  // For provider login with email/password
  const signInWithPassword = async (email: string, password: string) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      })

      if (error) throw error
      return { data, error: null }
    } catch (error) {
      console.error('Password sign in error:', error)
      return { data: null, error }
    }
  }

  // For regular user login with OAuth
  const signInWithOAuth = async (returnAction?: { type: string, [key: string]: any }) => {
    try {
      // Get the base URL based on environment
      const baseUrl = import.meta.env.VITE_APP_URL || window.location.origin;
      let redirectTo = `${baseUrl}/auth/callback`;
      
      // If we have a return action, add it to the redirect URL
      if (returnAction) {
        const returnParam = encodeURIComponent(JSON.stringify(returnAction));
        redirectTo += `?returnTo=${returnParam}`;
      }

      console.log('OAuth redirect URL:', redirectTo);

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo,
          queryParams: {
            access_type: 'offline'
          },
          skipBrowserRedirect: false, // Ensure browser handles the redirect
          scopes: 'email profile'
        }
      })
      
      // Log OAuth sign-in attempt
      console.log('OAuth sign-in initiated:', {
        provider: 'google',
        redirectTo
      })

      if (error) throw error
      return { error: null }
    } catch (error) {
      console.error('OAuth sign in error:', error)
      return { error }
    }
  }

  const signOut = async () => {
    try {
      // First clear any subscriptions
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe()
        subscriptionRef.current = null
      }

      // Clear user state
      setUser(null)
      
      // Sign out from Supabase
      const { error } = await supabase.auth.signOut()
      if (error) throw error

      // Clear stored session data
      localStorage.clear()
      
      console.log('Successfully signed out')
      return true
    } catch (error) {
      console.error('Error signing out:', error)
      return false
    }
  }

  return {
    user,
    loading,
    signIn: signInWithOAuth, // Default to OAuth for backward compatibility
    signInWithPassword, // Expose password login for providers
    signOut,
  }
}
