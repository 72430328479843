import { supabase } from './supabase';
import { supabaseAdmin } from './supabaseAdmin';
import type { Database } from '@/types/supabase';

export type Message = Database['public']['Tables']['messages']['Row'];

export type Conversation = Database['public']['Tables']['conversations']['Row'] & {
  user?: {
    id: string;
    email: string;
    user_metadata: {
      name: string;
    };
  };
  last_message?: Message | null;
  messages?: Message[];
  provider?: Provider;
  status: 'active' | 'archived';
};

export type Provider = Database['public']['Tables']['providers']['Row'];

type CreateProviderParams = Omit<Provider, 'created_at' | 'updated_at' | 'profile_image_url'>;

export const chatService = {
  async sendAutoReply(conversationId: string, providerId: string, providerName: string): Promise<Message | null> {
    try {
      const autoReply = `Thanks for approaching ${providerName}, I will be happy to assist you with anything. I got your details with Raze and I will be happy to answer any questions you may have.`;
      
      const { data: message, error } = await supabaseAdmin
        .from('messages')
        .insert({
          conversation_id: conversationId,
          sender_id: providerId,
          content: autoReply,
          timestamp: new Date().toISOString()
        })
        .select()
        .single();

      if (error) {
        console.error('Error sending auto-reply:', error);
        return null;
      }

      return { ...message, is_read: false };
    } catch (error) {
      console.error('Error in sendAutoReply:', error);
      return null;
    }
  },

  async createProvider(provider: CreateProviderParams): Promise<{ data: Provider | null; error: any }> {
    try {
      const { data, error } = await supabaseAdmin
        .from('providers')
        .insert(provider)
        .select('*')
        .single();

      if (error) return { data: null, error };
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  },

  async checkProviderExists(providerId: string): Promise<boolean> {
    try {
      console.log('Checking provider existence for:', providerId);
      
      // Check if provider record exists in providers table
      const { data: provider, error: providerError } = await supabaseAdmin
        .from('providers')
        .select('id')
        .eq('id', providerId)
        .maybeSingle();

      if (providerError) {
        console.error('Error checking provider:', providerError);
        return false;
      }

      return !!provider;
    } catch (error) {
      console.error('Error checking provider:', error);
      return false;
    }
  },

  async getConversationById(conversationId: string): Promise<Conversation> {
    const { data: conversation, error } = await supabaseAdmin
      .from('conversations')
      .select(`
        *,
        provider:providers(*),
        messages(*)
      `)
      .eq('id', conversationId)
      .single();

    if (error) {
      console.error('Error fetching conversation:', error);
      throw error;
    }

    return conversation;
  },

  async getOrCreateConversation(userId: string, providerId: string, isProviderInitiated: boolean = false): Promise<Conversation> {
    try {
      // First try to get existing conversation
      const { data: existingConversation, error: fetchError } = await supabaseAdmin
        .from('conversations')
        .select(`
          *,
          provider:providers(*),
          messages(*)
        `)
        .eq('user_id', isProviderInitiated ? providerId : userId)
        .eq('provider_id', providerId)
        .maybeSingle();

      if (fetchError) {
        console.error('Error fetching conversation:', fetchError);
        throw fetchError;
      }

      if (existingConversation) {
        return existingConversation;
      }

      // Create new conversation
      const { data: newConversation, error: createError } = await supabaseAdmin
        .from('conversations')
        .upsert({
          user_id: isProviderInitiated ? providerId : userId,
          provider_id: providerId,
          status: 'active',
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,provider_id'
        })
        .select(`
          *,
          provider:providers(*),
          messages(*)
        `)
        .maybeSingle();

      if (createError) {
        console.error('Error creating conversation:', createError);
        throw createError;
      }

      if (!newConversation) {
        // If no conversation was created, it means one already exists (race condition)
        // Try one more time to fetch it
        const { data: existingConv, error: retryError } = await supabaseAdmin
          .from('conversations')
          .select(`
            *,
            provider:providers(*),
            messages(*)
          `)
          .eq('user_id', isProviderInitiated ? providerId : userId)
          .eq('provider_id', providerId)
          .maybeSingle();

        if (retryError) throw retryError;
        if (!existingConv) throw new Error('Failed to create or find conversation');
        return existingConv;
      }

      return newConversation;
    } catch (error) {
      console.error('Error in getOrCreateConversation:', error);
      throw error;
    }
  },

  async getMessages(conversationId: string): Promise<Message[]> {
    try {
      const { data, error } = await supabaseAdmin
        .from('messages')
        .select('*')
        .eq('conversation_id', conversationId)
        .order('timestamp', { ascending: true });

      if (error) {
        console.error('Error fetching messages:', error);
        throw error;
      }

      return data || [];
    } catch (error) {
      console.error('Error in getMessages:', error);
      throw error;
    }
  },

  async sendMessage(conversationId: string, userId: string, content: string): Promise<Message> {
    try {
      const timestamp = new Date().toISOString();

      const { data, error } = await supabaseAdmin
        .from('messages')
        .insert({
          conversation_id: conversationId,
          sender_id: userId,
          content,
          timestamp
        })
        .select()
        .single();

      if (error) {
        console.error('Error sending message:', error);
        throw error;
      }

      // Update conversation timestamp
      await supabaseAdmin
        .from('conversations')
        .update({ updated_at: timestamp })
        .eq('id', conversationId);

      return data;
    } catch (error) {
      console.error('Error in sendMessage:', error);
      throw error;
    }
  },

  async markMessagesAsRead(conversationId: string, userId: string): Promise<void> {
    try {
      const { error } = await supabaseAdmin
        .from('messages')
        .update({ is_read: true })
        .eq('conversation_id', conversationId)
        .neq('sender_id', userId); // Only mark messages from other users as read

      if (error) {
        console.error('Error marking messages as read:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error in markMessagesAsRead:', error);
      throw error;
    }
  }
};
