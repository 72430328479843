import { FormData, Country, Profession, Purpose } from '@/types/form';

export const createRelocationPrompt = (formData: FormData): string => {
  const {
    familySize,
    purpose,
    otherPurpose,
    profession,
    otherProfession,
    partnerProfession,
    otherPartnerProfession,
    budget,
    passportCountries,
    partnerPassportCountries,
    housing,
    medicalNeeds,
    medicalNeedsDetails,
    destinationCountries,
    hasResidencePermit,
    additionalInfo,
    children,
    childrenAges,
    pets,
    otherFamilyMembers,
  } = formData;

  // Helper function to format arrays into readable strings
  function formatCountries(countries?: Country[]): string {
    if (!countries?.length) return '';
    return countries.map(c => c.name).join(', ');
  }

  // Format family size description
  const familyText = (() => {
    switch (familySize) {
      case 'single':
        return 'I am moving on my own';
      case 'couple':
        return 'I am moving with my partner';
      case 'family':
        const childText = children === 1 ? 'child' : 'children';
        const ageText = childrenAges?.length 
          ? ` (ages: ${childrenAges.join(', ')})` 
          : '';
        const petsText = pets ? ` and ${pets}` : '';
        const othersText = otherFamilyMembers ? ` and ${otherFamilyMembers}` : '';
        return `I am moving with my family including ${children} ${childText}${ageText}${petsText}${othersText}`;
      default:
        return '';
    }
  })();

  // Format purpose description
  const purposeText = (() => {
    if (!purpose) return '';
    
    switch (purpose) {
      case 'work':
        return 'for work';
      case 'study':
        return 'to study';
      case 'retirement':
        return 'for retirement';
      case 'digital_nomad':
        return 'as a digital nomad';
      case 'other':
        return otherPurpose ? `for ${otherPurpose}` : 'for other reasons';
      default:
        return '';
    }
  })();

  // Format budget description
  const budgetOptions = [
    { value: '0-10k', label: 'Less than $10,000' },
    { value: '10k-50k', label: 'Between $10,000 and $50,000' },
    { value: '50k-100k', label: 'Between $50,000 and $100,000' },
    { value: '100k+', label: 'Over $100,000' },
  ];

  const budgetText = budget === 'unsure' 
    ? 'not yet determined' 
    : budgetOptions.find(b => b.value === budget)?.label;

  const professionText = profession
    ? `I work as ${profession}${otherProfession ? ` (${otherProfession})` : ''}`
    : '';

  const partnerProfessionText = partnerProfession && familySize !== 'single'
    ? ` and my partner works as ${partnerProfession}${otherPartnerProfession ? ` (${otherPartnerProfession})` : ''}`
    : '';

  const housingPreference = housing === 'either' 
    ? 'either renting or buying property' 
    : housing === 'buying' 
      ? 'buying property' 
      : 'renting';

  const passportText = passportCountries?.length === 1 
    ? 'hold a passport from' 
    : 'have passports from';

  const partnerPassportText = partnerPassportCountries?.length && familySize !== 'single'
    ? ` and my partner holds ${partnerPassportCountries.length === 1 ? 'a passport from' : 'passports from'} ${formatCountries(partnerPassportCountries)}`
    : '';

  const destinationCountry = destinationCountries?.[0]?.name;

  if (!destinationCountry) {
    throw new Error('No destination country selected');
  }

  const prompt = `I am looking to relocate for ${purposeText}. ${familyText}

${professionText}${partnerProfessionText}

My budget for the relocation is ${budgetText}.

I ${passportText} ${formatCountries(passportCountries || [])}${partnerPassportText}.

For housing, I am interested in ${housingPreference}.

${medicalNeeds ? `Important medical consideration: ${medicalNeedsDetails}` : 'No special medical needs to consider.'}

I am planning to move to ${destinationCountry}.
${hasResidencePermit !== undefined ? `I ${hasResidencePermit ? 'already have' : 'do not have'} a residence permit or work visa for ${destinationCountry}.` : ''}

${additionalInfo ? `Additional information: ${additionalInfo}` : ''}

Please create a detailed relocation plan for ${destinationCountry} that addresses:

1. Visa Requirements:
   - Required visas and permits
   - Immigration documentation
   - Residence requirements
   - Application processes and timelines

2. Financial Planning:
   - Cost of living analysis
   - Banking and account setup
   - Tax considerations
   - Pension and retirement planning
   - Money transfer options

3. Health Insurance:
   - Healthcare system overview
   - Required medical insurance
   - Coverage options and costs
   - Medical facilities and services

4. Language Preparation:
   - Language requirements
   - Language learning resources
   - Communication strategies
   - Language schools and courses

5. Adaptation Strategies:
   - Cultural integration tips
   - Social networking opportunities
   - Local customs and etiquette
   - Community resources

6. Housing:
   - Housing market overview
   - ${housing === 'either' ? 'Rental and purchase options' : housing === 'buying' ? 'Property purchase process' : 'Rental market analysis'}
   - Recommended neighborhoods
   - Accommodation costs
   - Utilities and services

7. Education (if applicable):
   - School system overview
   - Educational options
   - Enrollment processes
   - Academic requirements
   - Costs and funding

8. Transportation:
   - Public transit systems
   - Driving requirements
   - Vehicle ownership/rental
   - Transportation costs

Please provide practical, actionable advice for each section, including specific steps, estimated costs, and timeframes where applicable. Format each section with a clear title followed by detailed bullet points.`;

  return prompt.trim();
};
