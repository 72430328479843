import { useState, useEffect } from 'react'
import { useAuth } from './useAuth'
import { FormData } from '@/types/form'
import { useNavigate, useLocation } from 'react-router-dom'
import { supabase } from '@/lib/supabase'
import type { Database } from '@/types/supabase'

type UserProgress = Database['public']['Tables']['user_progress']['Row'] & {
  form_data: FormData
}

export function useUserProgress() {
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [currentStep, setCurrentStep] = useState(1)
  const [formData, setFormData] = useState<FormData | null>(null)
  const [loading, setLoading] = useState(true)

  // Reset state when user logs out
  useEffect(() => {
    if (!user) {
      setCurrentStep(1);
      setFormData(null);
      setLoading(false);
    }
  }, [user]);

  // Load user progress when user is authenticated
  useEffect(() => {
    let isSubscribed = true;
    const loadUserProgress = async () => {
      if (!user) {
        setLoading(false)
        return
      }

      try {
        // Get the user's progress with a single query
        const { data, error } = await supabase
          .from('user_progress')
          .select('*')
          .eq('user_id', user.id)
          .order('updated_at', { ascending: false })
          .limit(1)
          .maybeSingle<UserProgress>(); // Use maybeSingle instead of single to avoid errors

        if (error && error.code !== 'PGRST116') throw error;

        if (data) {
          // Set the current step and form data
          setCurrentStep(data.current_step);
          setFormData(data.form_data as FormData);
          
          // Redirect to the current step if not already there
          const currentPath = location.pathname;
          const expectedPath = `/step/${data.current_step}`;
          if (currentPath !== expectedPath && 
              !currentPath.includes('/auth/callback') && 
              !currentPath.includes('/admin')) {
            navigate(expectedPath);
          }
        }
      } catch (error) {
        console.error('Error loading user progress:', error);
      } finally {
        setLoading(false);
      }
    };

    loadUserProgress();
  }, [user, location.pathname, navigate]);

  // Save user progress
  const saveProgress = async (step: number, data: FormData) => {
    if (!user) return;

    try {
      // Ensure we have the latest data before saving
      const formDataToSave = {
        ...data,
        additionalInfo: data.additionalInfo || '',
      };

      const { error } = await supabase
        .from('user_progress')
        .upsert({
          user_id: user.id,
          current_step: step,
          form_data: formDataToSave as unknown as Database['public']['Tables']['user_progress']['Insert']['form_data'],
          updated_at: new Date().toISOString()
        } satisfies Database['public']['Tables']['user_progress']['Insert'])
        .select()
        .single<UserProgress>();

      if (error) throw error;

      setCurrentStep(step);
      setFormData(formDataToSave);
    } catch (error) {
      console.error('Error saving progress:', error);
      throw error;
    }
  };

  return {
    currentStep,
    formData,
    loading,
    saveProgress,
  }
}
