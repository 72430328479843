import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Checkbox } from '@/components/ui/checkbox';
import { Mail, X, Send } from 'lucide-react';
import { FormData } from '@/types/form';
import { toast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';

interface SharePlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  formData: FormData;
  plan: string;
}

const CATEGORIES = [
  ['Visa', 'Finance', 'Insurance', 'Language'],
  ['Adaptation', 'Housing', 'Education', 'Transportation']
];

function formatFormData(formData: FormData): string {
  const formatField = (value: any) => value ? String(value) : null;
  const formatArray = (arr: any[] | undefined, key: string) => 
    arr?.length ? arr.map(item => item[key]).join(', ') : null;

  const fields = [
    ['Name', formatField(formData.name)],
    ['Age', formatField(formData.age)],
    ['Marital Status', formatField(formData.maritalStatus)],
    ['Passport Countries', formatArray(formData.passportCountries, 'name')],
    ['Destination Countries', formatArray(formData.destinationCountries, 'name')],
    ['Professional Background', formatArray(formData.professions, 'name')],
    ['Partner\'s Professional Background', formatArray(formData.partnerProfessions, 'name')],
    ['Family Size', formatField(formData.familySize)],
    ['Number of Children', formatField(formData.numberOfKids)],
    ['Children Ages', formData.kids?.length ? formData.kids.map(k => k.age).join(', ') : null],
    ['Budget', formatField(formData.budget)],
    ['Timeline', formatField(formData.timeline)],
    ['Additional Information', formatField(formData.additionalInfo)]
  ];

  const populatedFields = fields
    .filter(([_, value]) => value !== null)
    .map(([label, value]) => `${label}: ${value}`)
    .join('\n');

  return `User Profile:\n${populatedFields}`;
}

const SharePlanModal: React.FC<SharePlanModalProps> = ({
  isOpen,
  onClose,
  formData,
  plan
}) => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>(CATEGORIES.flat());
  const [emailContent, setEmailContent] = useState('');
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    // Initialize email content with form data and plan
    const content = `Hello,

I am reaching out to get your expert advice on my relocation plan. Below you'll find my profile details and the AI-generated plan. I would greatly appreciate your insights and recommendations.

${formatFormData(formData)}

AI-Generated Plan:
${plan}

Looking forward to your response.
Best regards,
Potential Customer`;
    
    setEmailContent(content);
  }, [formData, plan]);

  const handleSend = async () => {
    setIsSending(true);
    try {
      // Simulate sending delay
      await new Promise(resolve => setTimeout(resolve, 1500));
      toast({
        title: "Success!",
        description: "Your plan has been shared with relevant experts.",
      });
      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to share plan. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSending(false);
    }
  };

  const toggleCategory = (category: string) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl max-h-[90vh]" hideCloseButton>
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="flex items-center justify-between bg-blue-600 text-white p-6 rounded-t-lg">
            <div className="flex items-center gap-3">
              <Mail className="w-6 h-6" />
              <h2 className="text-xl font-semibold">Share Plan with Experts</h2>
            </div>
            <button
              onClick={onClose}
              className="text-white/70 hover:text-white transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Email Form */}
          <div className="flex-1 overflow-y-auto p-6 space-y-6">
            {/* To Field */}
            <div className="space-y-2">
              <Label className="text-gray-500">To</Label>
              <div className="bg-gray-50 p-3 rounded border text-gray-700">
                Relocation Experts Relevant to my Plan
              </div>
            </div>

            {/* Categories */}
            <div className="space-y-3">
              {CATEGORIES.map((row, rowIndex) => (
                <div key={rowIndex} className="grid grid-cols-4 gap-4">
                  {row.map(category => (
                    <div
                      key={category}
                      className={cn(
                        "flex items-center gap-2 p-3 rounded-lg border cursor-pointer transition-colors",
                        selectedCategories.includes(category)
                          ? "bg-blue-50 border-blue-200"
                          : "bg-white border-gray-200 hover:bg-gray-50"
                      )}
                      onClick={() => toggleCategory(category)}
                    >
                      <Checkbox
                        checked={selectedCategories.includes(category)}
                        onCheckedChange={() => toggleCategory(category)}
                      />
                      <span className="text-sm font-medium">{category}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>

            {/* Subject */}
            <div className="space-y-2">
              <Label className="text-gray-500">Subject</Label>
              <div className="bg-gray-50 p-3 rounded border text-gray-700">
                Looking for your advice following RazeMe AI plan
              </div>
            </div>

            {/* Message Body */}
            <div className="space-y-2">
              <Label className="text-gray-500">Message</Label>
              <Textarea
                value={emailContent}
                onChange={(e) => setEmailContent(e.target.value)}
                className="min-h-[400px] font-mono text-sm"
              />
            </div>
          </div>

          {/* Footer */}
          <div className="border-t bg-gray-50 p-6">
            <div className="flex justify-end">
              <Button
                onClick={handleSend}
                disabled={isSending}
                className="bg-blue-600 hover:bg-blue-700 text-white px-8"
              >
                <Send className="w-4 h-4 mr-2" />
                {isSending ? 'Sending...' : 'Send'}
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SharePlanModal;
