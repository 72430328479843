'use client'

import { FC, useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ArrowRight, ArrowLeft, PlusCircle, MinusCircle } from 'lucide-react';
import CountrySelect from '@/components/CountrySelect';
import ProfessionSelect from '@/components/ProfessionSelect';
import { useAuth } from '@/hooks/useAuth';
import { useUserProgress } from '@/hooks/useUserProgress';
import RelocationPlan from '@/components/RelocationPlan';
import { FormData, defaultFormData, familySizeOptions, kidsAgeGroups, Child, FamilySize, countryOptions } from '@/types/form';
import { 
  Select, 
  SelectContent, 
  SelectGroup,
  SelectItem, 
  SelectLabel,
  SelectTrigger, 
  SelectValue 
} from '@/components/ui/select';
import { Label } from '@/components/ui/label';
import { GoogleIcon } from '@/components/icons/GoogleIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { Check } from 'lucide-react';
import { getRecommendedDestinations } from '@/services/openai';
import { cn } from '@/lib/utils';

const steps = [
  {
    title: 'Personal',
    description: 'Tell us about your family',
  },
  {
    title: 'Purpose',
    description: 'Why are you relocating',
  },
  {
    title: 'Occupation',
    description: 'What do you do',
  },
  {
    title: 'Budget',
    description: 'Your relocation budget',
  },
  {
    title: 'Passport',
    description: 'Tell us about your citizenship',
  },
  {
    title: 'Housing',
    description: 'Your housing preferences',
  },
  {
    title: 'Health',
    description: 'Any health considerations',
  },
  {
    title: 'Destination',
    description: 'Choose your destination',
  },
  {
    title: 'Extras',
    description: 'Any other details',
  },
] as const;

const RelocationForm: FC = () => {
  const { user, signIn } = useAuth();
  const { currentStep: savedStep, formData: savedFormData, saveProgress } = useUserProgress();
  const navigate = useNavigate();
  const { step } = useParams<{ step: string }>();
  const [currentStep, setCurrentStep] = useState(parseInt(step || '1'));
  const [formData, setFormData] = useState<FormData>(() => {
    // Initialize with saved data if available, otherwise use default
    return savedFormData || defaultFormData;
  });
  const [finalFormData, setFinalFormData] = useState<FormData | null>(null);
  const [showPlan, setShowPlan] = useState(false);
  const [isLoadingRecommendations, setIsLoadingRecommendations] = useState(false);
  const [recommendations, setRecommendations] = useState<string | undefined>();
  const [showValidationError, setShowValidationError] = useState(false);
  const [showDestinationValidation, setShowDestinationValidation] = useState(false);

  // Update form state when saved progress changes
  useEffect(() => {
    if (savedFormData) {
      const mergedData = {
        ...formData,
        ...savedFormData,
        additionalInfo: savedFormData.additionalInfo || formData.additionalInfo || ''
      };
      setFormData(mergedData);
    }
  }, [savedFormData]);

  // Update recommendations when form data changes
  useEffect(() => {
    if (formData.recommendedDestinations) {
      setRecommendations(formData.recommendedDestinations);
    }
  }, [formData.recommendedDestinations]);

  const updateFormData = (update: Partial<FormData>) => {
    const updatedData = { ...formData, ...update };
    setFormData(updatedData);
  };

  const handleStepChange = async (newStep: number) => {
    if (user) {
      await saveProgress(newStep, formData);
    }
    setCurrentStep(newStep);
    navigate(`/step/${newStep}`);
  };

  const isStepValid = (currentStep: number): boolean => {
    switch (currentStep) {
      case 1: // Family Size
        return !!formData.familySize && (formData.familySize !== 'family' || (!!formData.children && formData.childrenAges?.length === formData.children));
      case 2: // Purpose
        return !!formData.purpose && (formData.purpose !== 'other' || !!formData.otherPurpose);
      case 3: // Occupation
        return !!formData.profession;
      case 4: // Budget
        return !!formData.budget;
      case 5: // Passport
        return (formData.passportCountries?.length ?? 0) > 0 && (formData.familySize === 'single' || (formData.partnerPassportCountries?.length ?? 0) > 0);
      case 6: // Housing
        return !!formData.housing;
      case 7: // Health
        return (
          formData.medicalNeeds !== undefined &&
          (!formData.medicalNeeds || (formData.medicalNeeds && !!formData.medicalNeedsDetails))
        );
      case 8: // Destination
        return (
          !!formData.destinationCountries?.length &&
          formData.hasResidencePermit !== undefined
        );
      case 9: // Extras
        return true;
      default:
        return true;
    }
  };

  const handleViewPlan = async (e?: React.MouseEvent) => {
    if (e) e.preventDefault(); // Prevent any default refresh behavior only if event exists

    // Always check for destination first, before any other operations
    if (!formData.destinationCountries?.length) {
      setShowValidationError(true);
      return;
    }

    // Only proceed with auth and navigation if we have destinations
    setShowValidationError(false);
    
    if (!user) {
      await signIn();
      return;
    }

    await saveProgress(currentStep, formData);
    navigate('/plan', { 
      state: { formData },
      replace: true 
    });
  };

  const handleNext = async () => {
    console.log('handleNext called', { currentStep, totalSteps: steps.length });
    
    if (!isStepValid(currentStep)) {
      console.log('Cannot proceed, missing required fields');
      return;
    }

    // If we're on the last step (8), show the plan
    if (currentStep >= steps.length) {
      console.log('On last step, showing plan');
      await handleViewPlan();
      return;
    }

    // Otherwise, go to next step
    console.log('Moving to next step');
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    await saveProgress(nextStep, formData);
    navigate(`/step/${nextStep}`, { replace: true });
  };

  const handleBack = async () => {
    if (currentStep > 1) {
      const prevStep = currentStep - 1;
      await handleStepChange(prevStep);
    }
  };

  const handleSubmit = async () => {
    if (!user) {
      await signIn();
      return;
    }

    if (!formData.destinationCountries?.length && !formData.needsDestinationHelp) {
      setShowValidationError(true);
      return;
    }

    await saveProgress(currentStep, formData);
    navigate('/plan', { 
      state: { formData },
      replace: true 
    });
  };

  const handleFamilySize = (size: FamilySize) => {
    const updates: Partial<FormData> = {
      familySize: size,
    };

    if (size === 'single') {
      updates.children = undefined;
      updates.childrenAges = [];
      updates.partnerProfession = undefined;
      updates.otherPartnerProfession = undefined;
      updates.partnerPassportCountries = [];
    }

    updateFormData(updates);
  };

  const handleChildren = (value: number) => {
    updateFormData({
      children: value,
      childrenAges: Array(value).fill('0')
    });
  };

  const handleChildAge = (index: number, age: string) => {
    const newAges = [...(formData.childrenAges || [])];
    newAges[index] = age;
    updateFormData({ childrenAges: newAges });
  };

  const renderKidsFields = () => {
    const numChildren = formData.children || 0;
    const currentAges = formData.childrenAges || [];

    if (numChildren === 0) return null;

    return (
      <div className="space-y-4">
        {Array.from({ length: numChildren }).map((_, index) => (
          <div key={index} className="flex items-center space-x-2">
            <Label>Child {index + 1} age:</Label>
            <Select
              key={index}
              value={formData.childrenAges?.[index] || ''}
              onValueChange={(value) => {
                const newAges = [...(formData.childrenAges || [])];
                newAges[index] = value;
                updateFormData({ childrenAges: newAges });
              }}
            >
              <SelectTrigger className="w-full bg-[#FEE5B5] text-black">
                <SelectValue placeholder={`${index + 1}${index === 0 ? 'st' : index === 1 ? 'nd' : index === 2 ? 'rd' : 'th'} child's age`} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Ages</SelectLabel>
                  {Array.from({ length: 18 }, (_, i) => (
                    <SelectItem
                      key={i}
                      value={`${i}`}
                    >
                      {i} years
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        ))}
      </div>
    );
  };

  const familySizeLabels: Record<FamilySize, string> = {
    single: 'Moving on my own',
    couple: 'Moving with a partner',
    family: 'Moving with my family'
  };

  // Ensure currentStep is within bounds
  const safeCurrentStep = Math.max(1, Math.min(currentStep, steps.length));
  const currentStepData = steps[safeCurrentStep - 1];

  if (showPlan && finalFormData) {
    return (
      <>
        <RelocationPlan 
          formData={finalFormData} 
          onBack={() => {
            setShowPlan(false);
            setFinalFormData(null);
          }} 
        />
      </>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-4xl mx-auto px-4 py-8 md:px-8">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center mb-8 sm:mb-12 text-[#6FC9E7]">
          Navigate Your Move
        </h1>
        <Card className="border-0 shadow-lg">
          <CardContent className="p-6">
            <div className="space-y-8">
              {/* Progress Steps */}
              <nav aria-label="Progress" className="mb-8">
                <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                  {steps.map((step, index) => (
                    <li key={step.title} className="md:flex-1">
                      <div
                        className={`group flex flex-col border-l-4 ${
                          index + 1 === currentStep
                            ? 'border-blue-600'
                            : index + 1 < currentStep
                            ? 'border-blue-600'
                            : 'border-gray-200'
                        } py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4`}
                      >
                        <div 
                          className="text-sm font-medium cursor-pointer"
                          onClick={() => {
                            const nextStep = index + 1;
                            if (nextStep <= currentStep) {
                              handleStepChange(nextStep);
                            }
                          }}
                        >
                          Step {index + 1}
                        </div>
                        <div className="text-sm text-gray-500">{step.title}</div>
                      </div>
                    </li>
                  ))}
                </ol>
              </nav>

              {/* Welcome Message - Only show in Step 1 */}
              {currentStep === 1 && (
                <div className="text-lg text-gray-700 space-y-4 mb-8">
                  <p>
                    Hi {user?.user_metadata?.full_name?.split(' ')[0] || 'there'}, I'm here to help you plan your relocation. 
                    Let's get started with a few quick questions—it'll only take a moment!
                  </p>
                  <p>Let's start by looking at who's moving with you?</p>
                </div>
              )}

              {/* Welcome Message - Only show in Step 2 */}
              {currentStep === 2 && (
                <div className="mb-6 space-y-2">
                  <p className="text-lg text-gray-700">
                    {(user?.user_metadata?.full_name?.split(' ')[0] || formData.name?.split(' ')[0] || 'Hi')}, Tell me more about your move!
                  </p>
                  <p className="text-lg text-gray-700">
                    What's bringing you to your new destination?
                  </p>
                </div>
              )}

              {/* Welcome Message - Only show in Step 4 */}
              {currentStep === 4 && (
                <div className="mb-6 space-y-2">
                  <p className="text-lg text-gray-700">
                    {(user?.user_metadata?.full_name?.split(' ')[0] || formData.name?.split(' ')[0] || 'Hi')}, Let's talk budget (no pressure!)
                  </p>
                  <p className="text-lg text-gray-700">
                    Do you have a budget in mind for your move?
                  </p>
                </div>
              )}

              {/* Welcome Message - Only show in Step 5 */}
              {currentStep === 5 && (
                <div className="mb-6 space-y-2">
                  <p className="text-lg text-gray-700">
                    {(user?.user_metadata?.full_name?.split(' ')[0] || formData.name?.split(' ')[0] || 'Hi')}, Passports and paperwork—let's sort this out!
                  </p>
                  <p className="text-lg text-gray-700">
                    What passports do you and your spouse hold?
                  </p>
                </div>
              )}

              {/* Welcome Message - Only show in Step 6 */}
              {currentStep === 6 && (
                <div className="mb-6 space-y-2">
                  <p className="text-lg text-gray-700">
                    Home sweet home — {(user?.user_metadata?.full_name?.split(' ')[0] || formData.name?.split(' ')[0] || 'Hi')}, what's your ideal housing situation?
                  </p>
                </div>
              )}

              {/* Welcome Message - Only show in Step 7 */}
              {currentStep === 7 && (
                <div className="mb-6 space-y-2">
                  <p className="text-lg text-gray-700">
                    Health and well-being are key!
                  </p>
                  <p className="text-lg text-gray-700">
                    {(user?.user_metadata?.full_name?.split(' ')[0] || formData.name?.split(' ')[0] || 'Hi')}, do you or your family members have any special medical needs?
                  </p>
                </div>
              )}

              {/* Welcome Message - Only show in Step 8 */}
              {currentStep === 8 && (
                <div className="mb-6 space-y-2">
                  <p className="text-lg text-gray-700">
                    {(user?.user_metadata?.full_name?.split(' ')[0] || formData.name?.split(' ')[0] || 'Hi')}, where do you want to relocate?
                  </p>
                </div>
              )}

              {/* Welcome Message - Only show in Step 9 */}
              {currentStep === 9 && (
                <div className="mb-6 space-y-2">
                  <p className="text-lg text-gray-700">
                    {(user?.user_metadata?.full_name?.split(' ')[0] || formData.name?.split(' ')[0] || 'Hi')}, Anything else we need to know about your relocation?
                  </p>
                </div>
              )}

              {/* Form Content */}
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentStep}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <Card className="bg-white border-2 border-[#6FC9E7] shadow-lg rounded-2xl">
                    <CardContent className="p-8">
                      <div className="space-y-8">
                        {/* Step 1: Personal */}
                        {currentStep === 1 && (
                          <Card>
                            <CardContent className="pt-6">
                              <div className="space-y-6">
                                <div className="grid grid-cols-1 gap-4">
                                  <Button
                                    type="button"
                                    variant={formData.familySize === 'single' ? "default" : "outline"}
                                    className="w-full py-6 text-lg"
                                    onClick={() => updateFormData({ familySize: 'single' })}
                                  >
                                    Moving on my own
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.familySize === 'couple' ? "default" : "outline"}
                                    className="w-full py-6 text-lg"
                                    onClick={() => updateFormData({ familySize: 'couple' })}
                                  >
                                    Moving with a partner
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.familySize === 'family' ? "default" : "outline"}
                                    className="w-full py-6 text-lg"
                                    onClick={() => updateFormData({ familySize: 'family' })}
                                  >
                                    Moving with my family
                                  </Button>
                                </div>

                                {formData.familySize === 'family' && (
                                  <div className="space-y-6">
                                    <div className="flex items-center justify-between bg-blue-50 p-4 rounded-lg">
                                      <Label className="text-base">Number of Children</Label>
                                      <div className="flex items-center space-x-4">
                                        <Button
                                          type="button"
                                          variant="outline"
                                          size="icon"
                                          onClick={() => {
                                            const newCount = Math.max(0, (formData.children || 0) - 1);
                                            updateFormData({ 
                                              children: newCount,
                                              childrenAges: Array(newCount).fill('0')
                                            });
                                          }}
                                          className="h-8 w-8 rounded-full"
                                        >
                                          <MinusCircle className="h-4 w-4" />
                                        </Button>
                                        <span className="text-xl font-medium">{formData.children || 0}</span>
                                        <Button
                                          type="button"
                                          variant="outline"
                                          size="icon"
                                          onClick={() => {
                                            const newCount = (formData.children || 0) + 1;
                                            updateFormData({ 
                                              children: newCount,
                                              childrenAges: [...(formData.childrenAges || []), '0']
                                            });
                                          }}
                                          className="h-8 w-8 rounded-full"
                                        >
                                          <PlusCircle className="h-4 w-4" />
                                        </Button>
                                      </div>
                                    </div>

                                    {(formData.children || 0) > 0 && (
                                      <div className="space-y-4">
                                        <Label className="text-base">Children's Ages</Label>
                                        <div className="grid grid-cols-2 gap-4">
                                          {Array.from({ length: formData.children || 0 }).map((_, index) => (
                                            <Select
                                              key={index}
                                              value={formData.childrenAges?.[index] || ''}
                                              onValueChange={(value) => {
                                                const newAges = [...(formData.childrenAges || [])];
                                                newAges[index] = value;
                                                updateFormData({ childrenAges: newAges });
                                              }}
                                            >
                                              <SelectTrigger className="w-full bg-[#FEE5B5] text-black">
                                                <SelectValue placeholder={`${index + 1}${index === 0 ? 'st' : index === 1 ? 'nd' : index === 2 ? 'rd' : 'th'} child's age`} />
                                              </SelectTrigger>
                                              <SelectContent>
                                                <SelectGroup>
                                                  <SelectLabel>Ages</SelectLabel>
                                                  {Array.from({ length: 18 }, (_, i) => (
                                                    <SelectItem
                                                      key={i}
                                                      value={`${i}`}
                                                    >
                                                      {i} years
                                                    </SelectItem>
                                                  ))}
                                                </SelectGroup>
                                              </SelectContent>
                                            </Select>
                                          ))}
                                        </div>
                                      </div>
                                    )}

                                    <div className="space-y-2">
                                      <Label className="text-base">Are any other family members moving with you?</Label>
                                      <textarea
                                        value={formData.otherFamilyMembers || ''}
                                        onChange={(e) => updateFormData({ otherFamilyMembers: e.target.value })}
                                        placeholder="E.g., parents, grandparents, or other relatives"
                                        className="w-full p-3 rounded-lg border border-[#FEE5B5] bg-[#FEE5B5] focus:outline-none min-h-[80px] text-black placeholder:text-gray-600"
                                      />
                                    </div>

                                    <div className="space-y-2">
                                      <Label className="text-base">Do you have any pets moving with you?</Label>
                                      <textarea
                                        value={formData.pets || ''}
                                        onChange={(e) => updateFormData({ pets: e.target.value })}
                                        placeholder="E.g., 2 cats and a dog"
                                        className="w-full p-3 rounded-lg border border-[#FEE5B5] bg-[#FEE5B5] focus:outline-none min-h-[80px] text-black placeholder:text-gray-600"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        )}

                        {/* Step 2: Purpose */}
                        {currentStep === 2 && (
                          <Card>
                            <CardContent className="pt-6">
                              <div className="space-y-4">
                                <div className="grid grid-cols-1 gap-4">
                                  <Button
                                    type="button"
                                    variant={formData.purpose === 'work' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.purpose === 'work'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ purpose: 'work', otherPurpose: '' })}
                                  >
                                    Work
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.purpose === 'study' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.purpose === 'study'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ purpose: 'study', otherPurpose: '' })}
                                  >
                                    Study
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.purpose === 'retirement' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.purpose === 'retirement'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ purpose: 'retirement', otherPurpose: '' })}
                                  >
                                    Retirement
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.purpose === 'digital_nomad' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.purpose === 'digital_nomad'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ purpose: 'digital_nomad' })}
                                  >
                                    Digital Nomad/Remote Worker
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.purpose === 'other' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.purpose === 'other'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ purpose: 'other' })}
                                  >
                                    Other
                                  </Button>
                                </div>

                                {formData.purpose === 'other' && (
                                  <div className="mt-4">
                                    <textarea
                                      value={formData.otherPurpose}
                                      onChange={(e) => updateFormData({ otherPurpose: e.target.value })}
                                      placeholder="Please specify your purpose..."
                                      className="w-full p-3 rounded-lg border border-[#FEE5B5] bg-[#FEE5B5] focus:outline-none min-h-[80px] text-black placeholder:text-gray-600"
                                    />
                                  </div>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        )}

                        {/* Step 3: Occupation */}
                        {currentStep === 3 && (
                          <Card>
                            <CardContent className="pt-6">
                              <div className="space-y-6">
                                <ProfessionSelect
                                  label="What do you do for living? (required)"
                                  placeholder="Select or type your occupation"
                                  selected={formData.profession}
                                  otherText={formData.otherProfession}
                                  onChange={(profession) => {
                                    if (profession) {
                                      updateFormData({ 
                                        profession,
                                        professions: [...(formData.professions || []), profession]
                                      });
                                    } else {
                                      updateFormData({ 
                                        profession: undefined,
                                        professions: formData.professions?.filter(p => p.id !== formData.profession?.id) || []
                                      });
                                    }
                                  }}
                                  onOtherChange={(otherProfession) => updateFormData({ otherProfession })}
                                />

                                {formData.familySize !== 'single' && (
                                  <ProfessionSelect
                                    label="What does your partner do for living? (required)"
                                    placeholder="Select or type your partner's occupation"
                                    selected={formData.partnerProfession}
                                    otherText={formData.otherPartnerProfession}
                                    onChange={(partnerProfession) => {
                                      if (partnerProfession) {
                                        updateFormData({ 
                                          partnerProfession,
                                          partnerProfessions: [...(formData.partnerProfessions || []), partnerProfession]
                                        });
                                      } else {
                                        updateFormData({ 
                                          partnerProfession: undefined,
                                          partnerProfessions: formData.partnerProfessions?.filter(p => p.id !== formData.partnerProfession?.id) || []
                                        });
                                      }
                                    }}
                                    onOtherChange={(otherPartnerProfession) => updateFormData({ otherPartnerProfession })}
                                  />
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        )}

                        {/* Step 4: Budget */}
                        {currentStep === 4 && (
                          <Card>
                            <CardContent className="pt-6">
                              <div className="space-y-4">
                                <div className="grid grid-cols-1 gap-4">
                                  <Button
                                    type="button"
                                    variant={formData.budget === '0-10k' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.budget === '0-10k'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ budget: '0-10k' })}
                                  >
                                    Less than $10,000
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.budget === '10k-25k' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.budget === '10k-25k'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ budget: '10k-25k' })}
                                  >
                                    $10,000 - $25,000
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.budget === '25k-50k' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.budget === '25k-50k'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ budget: '25k-50k' })}
                                  >
                                    $25,000 - $50,000
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.budget === '50k-100k' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.budget === '50k-100k'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ budget: '50k-100k' })}
                                  >
                                    $50,000 - $100,000
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.budget === '100k+' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.budget === '100k+'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ budget: '100k+' })}
                                  >
                                    Over $100,000
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.budget === 'unsure' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.budget === 'unsure'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ budget: 'unsure' })}
                                  >
                                    I'm not sure yet
                                  </Button>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        )}

                        {/* Step 5: Passport */}
                        {currentStep === 5 && (
                          <Card>
                            <CardContent className="pt-6">
                              <div className="space-y-6">
                                <div>
                                  <CountrySelect
                                    label="Your passports (select all that apply)"
                                    placeholder="Select all your passport countries"
                                    selected={formData.passportCountries || []}
                                    onChange={(passportCountries) => updateFormData({ passportCountries })}
                                  />
                                  {formData.passportCountries && formData.passportCountries.length > 0 && (
                                    <div className="mt-2 text-sm text-gray-600">
                                      Selected passports: {formData.passportCountries.map(c => c.name).join(', ')}
                                    </div>
                                  )}
                                </div>

                                {formData.familySize !== 'single' && (
                                  <div>
                                    <CountrySelect
                                      label="Your partner's passports (select all that apply)"
                                      placeholder="Select all your partner's passport countries"
                                      selected={formData.partnerPassportCountries || []}
                                      onChange={(partnerPassportCountries) => updateFormData({ partnerPassportCountries })}
                                    />
                                    {formData.partnerPassportCountries && formData.partnerPassportCountries.length > 0 && (
                                      <div className="mt-2 text-sm text-gray-600">
                                        Selected partner's passports: {formData.partnerPassportCountries.map(c => c.name).join(', ')}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        )}

                        {/* Step 6: Housing */}
                        {currentStep === 6 && (
                          <Card>
                            <CardContent className="pt-6">
                              <div className="space-y-4">
                                <div className="grid grid-cols-1 gap-4">
                                  <Button
                                    type="button"
                                    variant={formData.housing === 'renting' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.housing === 'renting'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ housing: 'renting' })}
                                  >
                                    Renting
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.housing === 'buying' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.housing === 'buying'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ housing: 'buying' })}
                                  >
                                    Buying
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.housing === 'either' ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.housing === 'either'
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ housing: 'either' })}
                                  >
                                    Either
                                  </Button>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        )}

                        {/* Step 7: Health */}
                        {currentStep === 7 && (
                          <Card>
                            <CardContent className="pt-6">
                              <div className="space-y-4">
                                <div className="grid grid-cols-1 gap-4">
                                  <Button
                                    type="button"
                                    variant={formData.medicalNeeds === false ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.medicalNeeds === false
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ medicalNeeds: false, medicalNeedsDetails: '' })}
                                  >
                                    No
                                  </Button>
                                  <Button
                                    type="button"
                                    variant={formData.medicalNeeds === true ? "default" : "outline"}
                                    className={`w-full py-6 text-lg ${
                                      formData.medicalNeeds === true
                                        ? 'bg-black hover:bg-black/90 text-white'
                                        : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => updateFormData({ medicalNeeds: true })}
                                  >
                                    Yes
                                  </Button>
                                </div>
                                
                                {formData.medicalNeeds && (
                                  <div className="mt-4">
                                    <textarea
                                      value={formData.medicalNeedsDetails || ''}
                                      onChange={(e) => updateFormData({ medicalNeedsDetails: e.target.value })}
                                      placeholder="Please tell us about any medical needs or considerations..."
                                      className="w-full p-3 rounded-lg bg-[#FEE5B5] focus:outline-none focus:ring-2 focus:ring-gray-200"
                                      rows={4}
                                    />
                                  </div>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        )}

                        {/* Step 8: Destination */}
                        {currentStep === 8 && (
                          <Card>
                            <CardContent className="pt-6">
                              <div className="space-y-6">
                                <CountrySelect
                                  label="Your destination country"
                                  placeholder="Select your destination country"
                                  selected={formData.destinationCountries || []}
                                  onChange={(countries) => {
                                    // Only take the most recently selected country
                                    const lastCountry = countries.slice(-1);
                                    updateFormData({ destinationCountries: lastCountry });
                                  }}
                                />

                                {formData.destinationCountries && formData.destinationCountries.length > 0 && (
                                  <div className="space-y-4 mt-6">
                                    <p className="text-base text-gray-700">
                                      Do you already have a residence permit or work visa for {formData.destinationCountries[0]?.name || 'this country'}?
                                    </p>
                                    <div className="grid grid-cols-1 gap-4">
                                      <Button
                                        type="button"
                                        variant={formData.hasResidencePermit === false ? "default" : "outline"}
                                        className={`w-full py-6 text-lg ${
                                          formData.hasResidencePermit === false
                                            ? 'bg-black hover:bg-black/90 text-white'
                                            : 'hover:bg-gray-50'
                                        }`}
                                        onClick={() => updateFormData({ hasResidencePermit: false })}
                                      >
                                        No
                                      </Button>
                                      <Button
                                        type="button"
                                        variant={formData.hasResidencePermit === true ? "default" : "outline"}
                                        className={`w-full py-6 text-lg ${
                                          formData.hasResidencePermit === true
                                            ? 'bg-black hover:bg-black/90 text-white'
                                            : 'hover:bg-gray-50'
                                        }`}
                                        onClick={() => updateFormData({ hasResidencePermit: true })}
                                      >
                                        Yes
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        )}

                        {/* Step 9: Extras */}
                        {currentStep === 9 && (
                          <Card>
                            <CardContent className="pt-6">
                              <textarea
                                value={formData.additionalInfo || ''}
                                onChange={(e) => updateFormData({ additionalInfo: e.target.value })}
                                placeholder="Share any additional details that might be relevant..."
                                className="w-full p-3 rounded-lg border border-gray-300 focus:border-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-200"
                                rows={4}
                              />
                            </CardContent>
                          </Card>
                        )}

                        {/* Navigation Buttons */}
                        <div className="flex justify-between items-center mt-8">
                          <Button
                            type="button"
                            variant="outline"
                            onClick={handleBack}
                            className={`flex items-center gap-2 ${currentStep === 1 ? 'invisible' : ''}`}
                          >
                            <ArrowLeft className="w-4 h-4" />
                            Back
                          </Button>
                          <Button
                            type="button"
                            onClick={handleNext}
                            disabled={!isStepValid(currentStep)}
                            className={`
                              flex items-center gap-2 bg-black hover:bg-black/90 text-white
                              disabled:bg-gray-300 disabled:hover:bg-gray-300
                              ${currentStep === steps.length ? 'bg-green-600 hover:bg-green-700' : ''}
                            `}
                          >
                            {currentStep === steps.length ? (
                              'Generate Plan'
                            ) : (
                              <>
                                Next
                                <ArrowRight className="w-4 h-4" />
                              </>
                            )}
                          </Button>
                        </div>

                        {/* Error Messages */}
                        {currentStep === 1 && !formData.familySize && (
                          <p className="text-sm text-red-500 mt-2">
                            Please select your family size
                          </p>
                        )}
                        {currentStep === 2 && !formData.purpose && (
                          <p className="text-sm text-red-500 mt-2">
                            Please select your purpose
                          </p>
                        )}
                        {currentStep === 3 && !formData.profession && (
                          <p className="text-sm text-red-500 mt-2">
                            Please select your occupation
                          </p>
                        )}
                        {currentStep === 4 && !formData.budget && (
                          <p className="text-sm text-red-500 mt-2">
                            Please select a budget range
                          </p>
                        )}
                        {currentStep === 5 && (!formData.passportCountries || formData.passportCountries.length === 0 || 
                          (formData.familySize !== 'single' && (!formData.partnerPassportCountries || formData.partnerPassportCountries.length === 0))) && (
                          <p className="text-sm text-red-500 mt-2">
                            Please select passport countries for both you and your partner
                          </p>
                        )}
                        {currentStep === 6 && !formData.housing && (
                          <p className="text-sm text-red-500 mt-2">
                            Please select your preferred housing situation
                          </p>
                        )}
                        {currentStep === 7 && formData.medicalNeeds !== undefined &&
                          (!formData.medicalNeeds || (formData.medicalNeeds && !formData.medicalNeedsDetails)) && (
                          <></>
                        )}
                        {currentStep === 8 && !formData.destinationCountries && (
                          <p className="text-sm text-red-500 mt-2">
                            Please select your destination countries
                          </p>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </motion.div>
              </AnimatePresence>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default RelocationForm;