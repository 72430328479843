import { FormData } from '@/types/form';
import { createRelocationPrompt } from '@/utils/promptTemplate';

const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';

export async function generateRelocationPlan(formData: FormData): Promise<string> {
  const prompt = createRelocationPrompt(formData);
  const apiKey = import.meta.env.VITE_OPENAI_API_KEY;

  if (!apiKey) {
    throw new Error('OpenAI API key is not configured');
  }

  try {
    const response = await fetch(OPENAI_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo-0125',
        messages: [{ role: 'user', content: prompt }],
        temperature: 0.7,
        max_tokens: 2000,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error?.message || 'Failed to generate relocation plan');
    }

    const data = await response.json();
    return data.choices[0]?.message?.content || 'Failed to generate relocation plan';
  } catch (error) {
    console.error('Error generating relocation plan:', error);
    throw error;
  }
}

export async function getRecommendedDestinations(formData: FormData): Promise<string> {
  const familyDescription = getFamilyDescription(formData);
  const professionsDescription = getProfessionsDescription(formData);
  const passportsDescription = getPassportsDescription(formData);
  const additionalInfo = formData.additionalInfo ? `Additional information: ${formData.additionalInfo}` : '';

  const prompt = `As a relocation expert, please recommend 3-5 countries that would be suitable for relocation based on the following information:

${familyDescription}
${professionsDescription}
${passportsDescription}
${additionalInfo}

Please provide a concise response that:
1. Lists the recommended countries
2. Briefly explains why each country would be a good fit
3. Considers factors like visa requirements, job market, and quality of life
4. Takes into account the family situation and professional background

Keep the response focused and to the point.`;

  console.log('Generated prompt:', prompt);
  const apiKey = import.meta.env.VITE_OPENAI_API_KEY;

  if (!apiKey) {
    console.error('OpenAI API key is missing');
    throw new Error('OpenAI API key is not configured');
  }

  try {
    console.log('Making request to OpenAI API...');
    const response = await fetch(OPENAI_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: 'gpt-4-1106-preview',
        messages: [{ role: 'user', content: prompt }],
        temperature: 0.7,
        max_tokens: 2000,
      }),
    });

    console.log('Response status:', response.status);
    const responseData = await response.json();
    console.log('Response data:', responseData);

    if (!response.ok) {
      console.error('OpenAI API error:', responseData);
      throw new Error(responseData.error?.message || 'Failed to get recommendations');
    }

    const content = responseData.choices[0]?.message?.content;
    if (!content) {
      console.error('No content in response:', responseData);
      throw new Error('No recommendations received from OpenAI');
    }

    console.log('Successfully received recommendations');
    return content;
  } catch (error) {
    console.error('Error getting recommendations:', error);
    throw error;
  }
}

function getFamilyDescription(formData: FormData): string {
  let desc = 'Family Situation: ';

  switch (formData.familySize) {
    case 'single':
      desc += 'Single individual';
      break;
    case 'couple':
      desc += 'Couple without children';
      break;
    case 'family':
      desc += `Family with ${formData.children || 0} child(ren)`;
      if (formData.childrenAges && formData.childrenAges.length > 0) {
        desc += ` (Ages: ${formData.childrenAges.join(', ')})`;
      }
      break;
    default:
      desc += 'Information not provided';
  }

  return desc;
}

function getProfessionsDescription(formData: FormData): string {
  let desc = `Professional Background: `;
  
  if (formData.profession) {
    desc += `Individual's profession: ${formData.profession.name}`;
  } else if (formData.professions?.length) {
    desc += `Individual's profession(s): ${formData.professions.map(p => p.name).join(', ')}`;
  }
  
  if (formData.familySize !== 'single' && formData.partnerProfession) {
    desc += `\nPartner's profession: ${formData.partnerProfession.name}`;
  } else if (formData.familySize !== 'single' && formData.partnerProfessions?.length) {
    desc += `\nPartner's profession(s): ${formData.partnerProfessions.map(p => p.name).join(', ')}`;
  }
  
  return desc;
}

function getPassportsDescription(formData: FormData): string {
  let desc = `Citizenship: `;
  
  if (formData.passportCountries?.length) {
    desc += `Individual holds passport(s) from: ${formData.passportCountries.map(c => c.name).join(', ')}`;
  }
  
  if (formData.familySize !== 'single' && formData.partnerPassportCountries?.length) {
    desc += `\nPartner holds passport(s) from: ${formData.partnerPassportCountries.map(c => c.name).join(', ')}`;
  }
  
  return desc;
}
