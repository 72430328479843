import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useUserProgress } from '@/hooks/useUserProgress';
import RelocationForm from '@/components/RelocationForm';

const Step: FC = () => {
  const { step } = useParams<{ step: string }>();
  const { currentStep, formData, saveProgress, loading } = useUserProgress();

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <RelocationForm />
    </div>
  );
};

export default Step;
