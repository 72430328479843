import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createBrowserClient } from '@/lib/supabase-browser'
import { useRedirectAfterAuth } from '@/hooks/useRedirectAfterAuth'

export default function AuthCallback() {
  const navigate = useNavigate()
  const supabase = createBrowserClient()
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [userId, setUserId] = useState<string>()

  // Use our custom hook for redirection
  useRedirectAfterAuth(userId)

  // Handle authentication callback and redirect user appropriately
  useEffect(() => {
    const handleAuthCallback = async () => {
      console.log('Starting auth callback handling, URL:', window.location.href)
      try {
        setIsLoading(true)
        
        // Check if we have an error in the URL
        const params = new URLSearchParams(window.location.search)
        const error = params.get('error')
        const errorDescription = params.get('error_description')
        
        if (error) {
          console.error('Auth error:', error, errorDescription)
          setError(errorDescription || error)
          return
        }

        // Get the session directly from Supabase
        // Supabase will automatically handle the code exchange if present
        const { data: { session }, error: sessionError } = await supabase.auth.getSession()
        
        if (sessionError) {
          console.error('Session error:', sessionError)
          setError(sessionError.message)
          return
        }

        if (!session) {
          console.error('No session available')
          setError('Failed to establish session')
          return
        }

        console.log('Session established:', session)
        setUserId(session.user.id)

        if (session.access_token) {
          console.log('Found access token, attempting to set session')
          const { data: { user }, error: setSessionError } = await supabase.auth.setSession({
            access_token: session.access_token,
            refresh_token: session.refresh_token || ''
          })

          if (setSessionError) {
            console.error('Error setting session:', setSessionError)
            setError(setSessionError.message)
            return
          }

          if (user) {
            setUserId(user.id)
          }
        } else {
          setError('No access token found')
          navigate('/')
        }
      } catch (error) {
        console.error('Auth callback error:', error)
        setError(error instanceof Error ? error.message : 'An error occurred')
        navigate('/')
      } finally {
        setIsLoading(false)
      }
    }

    handleAuthCallback()
  }, [navigate])

  if (error) {
    return <div className="p-4 text-red-500">Error: {error}</div>
  }

  if (isLoading) {
    return <div className="p-4">Loading...</div>
  }

  return null
}
