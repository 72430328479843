import React, { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { Country as CountryType } from '@/types/form';
import { Step as StepType } from '@/types/admin';

type Country = CountryType;
type Step = StepType;

export default function CountrySteps() {
  const [countries, setCountries] = useState<Country[]>([]);
  const [steps, setSteps] = useState<Step[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch countries
        const { data: countriesData, error: countriesError } = await supabase
          .from('countries')
          .select('code, name')
          .order('name');

        if (countriesError) throw countriesError;
        setCountries(countriesData as Country[]);

        // If a country is selected, fetch its steps
        if (selectedCountry) {
          const { data: stepsData, error: stepsError } = await supabase
            .from('country_steps')
            .select('*')
            .eq('country_code', selectedCountry)
            .order('sort_order');

          if (stepsError) throw stepsError;
          setSteps(stepsData as Step[]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCountry]);

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(e.target.value);
  };

  if (loading) {
    return <div className="text-center py-12">Loading...</div>;
  }

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium">Select Country</label>
        <select
          value={selectedCountry}
          onChange={handleCountryChange}
          className="mt-1 block w-full rounded-md border p-2"
        >
          <option value="">Select a country</option>
          {countries.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </select>
      </div>

      {selectedCountry && (
        <div className="space-y-4">
          <h2 className="text-xl font-bold">Steps</h2>
          {steps.map((step, index) => (
            <div key={step.id} className="rounded-lg border p-4">
              <h3 className="font-semibold">
                {index + 1}. {step.title}
              </h3>
              <p className="mt-2 text-gray-600">{step.description}</p>
              <div className="mt-4 whitespace-pre-wrap">{step.description}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}