import React from 'react';
import type { LucideIcon } from 'lucide-react';
import {
    FileText,
    Wallet,
    Heart,
    Languages,
    Users,
    Home,
    GraduationCap,
    Car
} from 'lucide-react';

export interface PlanSection {
    title: string;
    icon: LucideIcon;
    content: string[];
    gradient: string;
    description: string;
}

const DEBUG = true;

interface CategoryMatch {
    normalizedTitle: string;
    icon: LucideIcon;
    gradient: string;
    description: string;
    priority: number;
}

const findCategory = (text: string): CategoryMatch | null => {
    // Order matters - more specific categories should come first
    const categories: Array<[RegExp, CategoryMatch]> = [
        [
            /visa|passport|immigration|document|requirement|permit|residence|citizenship/i,
            {
                normalizedTitle: "Visa Requirements",
                icon: FileText,
                gradient: "from-blue-400 to-blue-600",
                description: "Essential visa and documentation requirements",
                priority: 1
            }
        ],
        [
            /financ|cost|budget|expense|tax|pension|money|planning|salary|income|bank|account/i,
            {
                normalizedTitle: "Financial Planning",
                icon: Wallet,
                gradient: "from-emerald-400 to-emerald-600",
                description: "Financial planning and cost estimation",
                priority: 2
            }
        ],
        [
            /health|insurance|medical|healthcare|coverage|hospital|doctor|clinic/i,
            {
                normalizedTitle: "Health Insurance",
                icon: Heart,
                gradient: "from-red-400 to-red-600",
                description: "Healthcare and insurance requirements",
                priority: 3
            }
        ],
        [
            /language|speak|commun|preparation|german|english|course|class|learn/i,
            {
                normalizedTitle: "Language Preparation",
                icon: Languages,
                gradient: "from-indigo-400 to-indigo-600",
                description: "Language learning resources and strategies",
                priority: 4
            }
        ],
        [
            /adapt|cultur|integrat|network|social|custom|strateg|community|local|lifestyle/i,
            {
                normalizedTitle: "Adaptation Strategies",
                icon: Users,
                gradient: "from-purple-400 to-purple-600",
                description: "Cultural integration and networking tips",
                priority: 5
            }
        ],
        [
            /hous|rent|apartment|accommodation|living|property|real estate|landlord|tenant/i,
            {
                normalizedTitle: "Housing",
                icon: Home,
                gradient: "from-orange-400 to-orange-600",
                description: "Housing options and considerations",
                priority: 6
            }
        ],
        [
            /educat|school|study|learn|academic|university|college|kindergarten|child care/i,
            {
                normalizedTitle: "Education",
                icon: GraduationCap,
                gradient: "from-yellow-400 to-yellow-600",
                description: "Educational opportunities and enrollment",
                priority: 7
            }
        ],
        [
            /transport|travel|commut|car|transit|mobility|bus|train|metro|driving|license/i,
            {
                normalizedTitle: "Transportation",
                icon: Car,
                gradient: "from-teal-400 to-teal-600",
                description: "Transportation and mobility options",
                priority: 8
            }
        ]
    ];

    for (const [pattern, category] of categories) {
        if (pattern.test(text)) {
            if (DEBUG) console.log(`Category match found: "${category.normalizedTitle}" for text: "${text}"`);
            return category;
        }
    }

    return null;
};

export const processPlanIntoSections = (planText: string): PlanSection[] => {
    if (DEBUG) console.log('Starting plan processing with text:', planText);

    const cleanedText = planText.replace(/\r\n/g, '\n').trim();
    const sections = cleanedText.split(/\n\s*\n/).filter(Boolean);

    if (DEBUG) {
        console.log('Split into sections:', sections);
        console.log('Number of sections found:', sections.length);
    }

    const categoryMap = new Map<string, {
        icon: LucideIcon;
        gradient: string;
        content: string[];
        description: string;
    }>();

    sections.forEach((section, index) => {
        if (DEBUG) console.log(`\nProcessing section ${index + 1}:`, section);

        const lines = section.split('\n');
        const rawTitle = lines[0].replace(/[:：]/g, '').trim();
        let content = lines.slice(1).map(line => line.trim()).filter(Boolean);

        if (DEBUG) {
            console.log('Raw title:', rawTitle);
            console.log('Content lines:', content);
        }

        // Clean up bullet points first
        content = content.map(line => line.replace(/^[-•●]\s*/, ''));

        // Check title for categories
        const titleCategory = findCategory(rawTitle);
        if (titleCategory) {
            if (DEBUG) console.log(`Title matches category: ${titleCategory.normalizedTitle}`);
            const key = titleCategory.normalizedTitle;
            if (!categoryMap.has(key)) {
                categoryMap.set(key, {
                    icon: titleCategory.icon,
                    gradient: titleCategory.gradient,
                    content: [],
                    description: titleCategory.description
                });
            }
            // If title matches a category, all content goes to that category
            categoryMap.get(key)!.content.push(...content);
            return; // Skip content analysis for this section since we've categorized it by title
        }

        // If no title match, analyze each line individually
        content.forEach(line => {
            const contentCategory = findCategory(line);
            if (contentCategory) {
                if (DEBUG) console.log(`Line "${line}" matches category: ${contentCategory.normalizedTitle}`);
                const key = contentCategory.normalizedTitle;
                if (!categoryMap.has(key)) {
                    categoryMap.set(key, {
                        icon: contentCategory.icon,
                        gradient: contentCategory.gradient,
                        content: [],
                        description: contentCategory.description
                    });
                }
                // Only add the matching line to the category
                categoryMap.get(key)!.content.push(line);
            } else {
                // If line doesn't match any category, add it to "Additional Information"
                const key = "Additional Information";
                if (!categoryMap.has(key)) {
                    categoryMap.set(key, {
                        icon: FileText,
                        gradient: "from-gray-400 to-gray-600",
                        content: [],
                        description: "Additional relocation information"
                    });
                }
                categoryMap.get(key)!.content.push(line);
            }
        });
    });

    if (DEBUG) {
        console.log('\nFinal category map:', Array.from(categoryMap.entries()));
    }

    // Sort sections by priority
    return Array.from(categoryMap.entries())
        .map(([title, { icon, gradient, content, description }]) => ({
            title,
            icon,
            gradient,
            content: Array.from(new Set(content)), // Remove any duplicate content
            description,
        }))
        .sort((a, b) => {
            const priorities: { [key: string]: number } = {
                "Visa Requirements": 1,
                "Financial Planning": 2,
                "Health Insurance": 3,
                "Language Preparation": 4,
                "Adaptation Strategies": 5,
                "Housing": 6,
                "Education": 7,
                "Transportation": 8,
                "Additional Information": 9
            };
            return (priorities[a.title] || 10) - (priorities[b.title] || 10);
        });
};